import { createAction, props } from '@ngrx/store';
import {StreamConfiguration} from '../../../services/api/stream-configurations/stream-configuration.model';

export const loadStreamConfiguration = createAction(
  '[StreamConfiguration] Load Stream Configuration',
  props<{ id: string }>()
);

export const loadStreamConfigurationSuccess = createAction(
  '[StreamConfiguration] Load Stream Configuration Success',
  props<{ data: StreamConfiguration }>()
);

export const loadStreamConfigurationFailure = createAction(
  '[StreamConfiguration] Load Stream Configuration Failure',
  props<{ error: any }>()
);

export const clearStreamConfigurationState = createAction(
  '[StreamConfiguration] Clear Stream Configuration State',
);
