import {createReducer, on} from '@ngrx/store';
import {StreamConfiguration} from '../../../services/api/stream-configurations/stream-configuration.model';
import {loadStreamConfiguration, loadStreamConfigurationFailure, loadStreamConfigurationSuccess} from './stream-configuration.load.actions';

export interface State {
  record: StreamConfiguration | null;
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: null,
  error: null,
  loaded: false,
  loading: false
};


export const loadStreamConfigurationReducer = createReducer(
  initialState,

  on(loadStreamConfiguration, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: true
  })),
  on(loadStreamConfigurationSuccess, (state: State, action) => ({
    record: action.data,
    error: null,
    loaded: true,
    loading: false
  })),
  on(loadStreamConfigurationFailure, (state: State, action) => ({
    record: null,
    error: action.error,
    loaded: false,
    loading: false
  })),
  on(loadStreamConfigurationFailure, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: false
  }))
);
