import { createReducer, on } from '@ngrx/store';
import * as ClustersActions from '../actions/clusters.actions';
import { Utils } from '../../../services/Utils';
import {Cluster} from '../../../services/api/clusters/models/cluster.model';

export const clustersFeatureKey = 'clusters';

export interface State {
  record: Cluster[];
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: [],
  error: null,
  loaded: false,
  loading: false,
};

function processUpdateAction(state: State, action: any): any[] {
  const recordId = action.data.data.id;
  const recordIndex = state.record.findIndex(item => item.id === recordId);

  const clustersArray: any[] = state.record.slice();
  if (recordIndex > -1) {
    clustersArray[recordIndex] = action.data.data;
  }

  return clustersArray;
}

function processCreateAction(state: State, action: any): any[] {
  const recordId = action.data.data.id;
  const recordIndex = state.record.findIndex(item => item.id === recordId);

  const clustersArray: any[] = state.record.slice();
  const incomingCluster = action.data.data;
  if (recordIndex > -1) {
    clustersArray[recordIndex] = incomingCluster;
  } else {
    clustersArray.push(incomingCluster);
  }

  return clustersArray;
}

function processDeleteAction(state: State, action: any): any[] {
  const recordId = action.data.data.id;
  const recordIndex = state.record.findIndex(item => item.id === recordId);

  const clustersArray: any[] = state.record.slice();
  if (recordIndex > -1) {
    clustersArray.splice(recordIndex, 1);
  }

  return clustersArray;
}

function processAction(state: State, action: any): any[] {
  const actionType: 'create' | 'update' | 'delete' = action.data.action;
  switch (actionType) {
    case 'create':
      return processCreateAction(state, action);
    case 'update':
      return processUpdateAction(state, action);
    case 'delete':
      return processDeleteAction(state, action);
    default:
      return processUpdateAction(state, action);
  }
}

export const reducer = createReducer(
  initialState,

  on(ClustersActions.loadClusters, (state) => ({
    ...state,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(ClustersActions.loadClustersSuccess, (state, action) => ({
    record: Utils.sortArrayByKey(action.data, 'name'),
    error: null,
    loaded: true,
    loading: false,
  })),
  on(ClustersActions.updateClusterStatus, (state, action) => {
    const data: any[] = processAction(state, action);
    return {
      record: data,
      error: null,
      loaded: true,
      loading: false
    };
  }),
  on(ClustersActions.loadClustersFailure, (state, action) => ({
    record: [],
    error: action.error,
    loaded: false,
    loading: false,
  })),
  on(ClustersActions.clearClustersState, () => ({
    record: [],
    error: null,
    loaded: false,
    loading: false,
  })),
);
