import {createReducer, on} from '@ngrx/store';
import {StreamConfiguration} from '../../../services/api/stream-configurations/stream-configuration.model';
import {
  clearStreamConfigurationsState,
  loadStreamConfigurations,
  loadStreamConfigurationsFailure,
  loadStreamConfigurationsSuccess
} from './stream-configurations.load-all.actions';
import {StreamConfigurationsRequestParameters} from '../../../services/api/stream-configurations/stream-configurations.service';


export interface State {
  record: StreamConfiguration[];
  error: any;
  loaded: boolean;
  loading: boolean;
  parameters: StreamConfigurationsRequestParameters | null;
}

export const initialState: State = {
  record: null,
  error: null,
  loaded: false,
  loading: false,
  parameters: null
};


export const loadAllStreamConfigurationReducer = createReducer(
  initialState,

  on(loadStreamConfigurations, (state: State, action) => ({
    record: state.record,
    error: null,
    loaded: false,
    loading: true,
    parameters: action
  })),
  on(loadStreamConfigurationsSuccess, (state: State, action) => ({
    record: action.data,
    error: null,
    loaded: true,
    loading: false,
    parameters: state.parameters
  })),
  on(loadStreamConfigurationsFailure, (state: State, action) => ({
    record: [],
    error: action.error,
    loaded: false,
    loading: false,
    parameters: state.parameters
  })),
  on(clearStreamConfigurationsState, () => ({
    record: [],
    error: null,
    loaded: false,
    loading: false,
    parameters: null
  }))
);
