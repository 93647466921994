import {createReducer, on} from '@ngrx/store';
import {DestinationStatus} from '../../../services/api/destinations/interfaces/destination-status.interface';
import {
  clearDestinationsState,
  loadDestinationsStatues,
  loadDestinationsStatusesFailure,
  loadDestinationsStatusesSuccess
} from './destinations-statuses.actions';

export interface State {
  record: DestinationStatus[];
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: null,
  error: null,
  loaded: false,
  loading: false
};


export const loadDestinationsStatusesReducer = createReducer(
  initialState,

  on(loadDestinationsStatues, (state) => ({
    ...state,
    error: null,
    loaded: false,
    loading: true
  })),
  on(loadDestinationsStatusesSuccess, (state, action) => ({
    record: action.data,
    error: null,
    loaded: true,
    loading: false
  })),
  on(loadDestinationsStatusesFailure, (state, action) => ({
    record: null,
    error: action.error,
    loaded: false,
    loading: false
  })),
  on(clearDestinationsState, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: false
  }))
);

