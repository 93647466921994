import {ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DestinationRtmpTarget} from '../../../services/api/destinations/interfaces/destination.model';
import {AuthScheme, AuthSchemeName} from '../../../services/api/destinations/auth-schemes/auth-scheme.model';

@Component({
  selector: 'app-destination-rtmp-push-form',
  templateUrl: './destination-rtmp-push-form.component.html',
  styleUrls: ['./destination-rtmp-push-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DestinationRtmpPushFormComponent implements OnChanges, OnDestroy {
  @Input() destinationControl: FormGroup;
  @Input() destination: DestinationRtmpTarget;
  @Input() authSchemes: AuthScheme[];
  public selectedScheme: AuthScheme;

  public ngOnChanges(changes: SimpleChanges): void {
    if ((changes['destinationControl'] || changes['authSchemes']) && this.destinationControl && this.authSchemes) {
      if (this.destination) {
        const authScheme: AuthScheme | undefined = this.authSchemes
          .find((item: AuthScheme): boolean => item.name === this.destination.authScheme);
        if (authScheme !== undefined) {
          this.selectedScheme = authScheme;
        }
        this.createControls(this.destination);
      } else {
        const defaultAuthScheme: AuthScheme | undefined = this.authSchemes.find((item: AuthScheme): boolean => item.name === 'NONE');
        if (defaultAuthScheme !== undefined) {
          this.selectedScheme = defaultAuthScheme;
        }
        this.createControls();
      }
    }
  }

  createControls(destination: DestinationRtmpTarget = {
    protocol: 'RTMP',
    address: null,
    application: null,
    port: 1935,
    streamKey: null,
    authScheme: this.selectedScheme?.name,
    username: null,
    password: null,
    akamaiStreamId: null
  }) {
    let streamUrl: string | null = null;
    if (destination.address && destination.application) {
      streamUrl = `${destination.address}/${destination.application}`;
    }

    this.destinationControl.addControl('protocol', new FormControl(destination.protocol, Validators.required));
    this.destinationControl.addControl('streamUrl', new FormControl(streamUrl, Validators.required));
    this.destinationControl.addControl('port', new FormControl(destination.port, Validators.required));
    this.destinationControl.addControl('streamKey', new FormControl(destination.streamKey, Validators.required));
    this.destinationControl.addControl('authScheme', new FormControl(destination.authScheme, Validators.required));
    this.destinationControl.addControl('username', new FormControl(destination.username));
    this.destinationControl.addControl('password', new FormControl(destination.password));

    if (destination.akamaiStreamId && destination.akamaiStreamId !== '') {
      this.destinationControl.addControl('akamaiStreamId', new FormControl(destination.akamaiStreamId, Validators.required));
    }
  }

  public onPasteRtmpStreamUrl(): void {
    setTimeout(() => {
      let streamKey = '';
      let streamUrlValue = this.destinationControl.get('streamUrl').value;

      if (streamUrlValue.indexOf(':/') !== -1) {
        const end: number = streamUrlValue.indexOf(':/');
        const protocol: string = streamUrlValue.slice(0, end).toUpperCase();
        this.destinationControl.get('protocol').setValue(protocol);
        this.onChangeProtocol();

        streamUrlValue = streamUrlValue.slice(end + 3);
      }

      if (streamUrlValue.indexOf(':') !== -1) {
        const start: number = streamUrlValue.indexOf(':');
        const end: number = streamUrlValue.indexOf('/');
        const isValidRange: boolean = start < end;

        if (isValidRange) {
          const port = streamUrlValue.slice(streamUrlValue.indexOf(':') + 1, streamUrlValue.indexOf('/'));
          this.destinationControl.get('port').setValue(Number(port));
          streamUrlValue = streamUrlValue.slice(0, streamUrlValue.indexOf(':')) + streamUrlValue.slice(streamUrlValue.indexOf('/'));
        }
      } else {
        this.onChangeProtocol();
      }

      /* Trick for URLs like this:
      * rtmp://vvcr.live:1935/live/streams/stream-key
      */
      const slashesCount = streamUrlValue.split('/').length - 1;
      if (slashesCount > 1) {
        const lastSlashIndex: number = streamUrlValue.lastIndexOf('/');
        const prevSlashIndex: number = streamUrlValue.slice(0, lastSlashIndex).lastIndexOf('/');
        const dataAfterLastSlash: string = streamUrlValue.slice(lastSlashIndex + 1);
        if (dataAfterLastSlash.length > 0) {
          /* Examples:
            "rtmp://vvcr.live:1935/live/stream-key"
            "rtmp://vvcr.live:1935/live/streams/stream-key"
          */
          streamKey = dataAfterLastSlash;
          streamUrlValue = streamUrlValue.slice(0, lastSlashIndex);
        } else if (slashesCount > 2) {
          /* Example:
            "rtmp://vvcr.live:1935/live/stream-key/"
            "rtmp://vvcr.live:1935/live/streams/stream-key/"
          */
          streamKey = streamUrlValue.slice(prevSlashIndex + 1, lastSlashIndex);
          streamUrlValue = streamUrlValue.slice(0, prevSlashIndex);
        }

        this.destinationControl.get('streamKey').setValue(streamKey);
      }

      this.destinationControl.get('streamUrl').setValue(streamUrlValue);
    });
  }

  public onChangeProtocol(): void {
    const protocol = this.destinationControl.get('protocol').value;
    console.log('Protocol: ', protocol);
    if (protocol === 'RTMP') {
      this.destinationControl.get('port').setValue(1935);
    } else {
      this.destinationControl.get('port').setValue(443);
    }
  }

  public onChangeScheme(): void {
    const schemeName: AuthSchemeName = this.destinationControl.get('authScheme').value;
    const scheme: AuthScheme | undefined = this.authSchemes.find((item: AuthScheme): boolean => item.name === schemeName);
    if (scheme !== undefined) {
      this.selectedScheme = scheme;
    }
    if (this.selectedScheme.streamId) {
      this.destinationControl.addControl('akamaiStreamId', new FormControl('', Validators.required));
    } else {
      this.destinationControl.removeControl('akamaiStreamId');
    }
  }

  public ngOnDestroy(): void {
    this.removeControls();
  }

  private removeControls() {
    this.destinationControl.removeControl('streamUrl');
    this.destinationControl.removeControl('streamKey');
    this.destinationControl.removeControl('authSchemeId');
    this.destinationControl.removeControl('username');
    this.destinationControl.removeControl('password');
    this.destinationControl.removeControl('akamaiStreamId');
  }

}
