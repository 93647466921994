import { createAction, props } from '@ngrx/store';
import {CreateStreamConfiguration} from '../../../services/api/stream-configurations/stream-configuration.create.model';
import {StreamConfiguration} from '../../../services/api/stream-configurations/stream-configuration.model';

export const updateStreamConfiguration = createAction(
  '[StreamConfigurationUpdate] Stream Configuration Update',
  props<{ id: string, data: CreateStreamConfiguration }>()
);

export const updateStreamConfigurationSuccess = createAction(
  '[StreamConfigurationUpdate] Stream Configuration Update Success',
  props<{ data: StreamConfiguration }>()
);

export const updateStreamConfigurationFailure = createAction(
  '[StreamConfigurationUpdate] Stream Configuration Update Failure',
  props<{ error: any }>()
);

export const clearUpdateStreamConfigurationState = createAction(
  '[StreamConfigurationUpdate] clear Stream Configuration Update State',
);
