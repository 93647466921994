export class DataTransform {

  public static findObjInArrayByKeyAndReplaceIfKeyChanged(array: any[] | null, obj: any, key: any, keyCheck: any) {
    if (array === null || array.length === 0) {
      return false;
    }
    const newArray = array.slice();
    const foundIndex = array.findIndex(x => x[key] === obj[key]);
    if (newArray[foundIndex][keyCheck] !== obj[keyCheck]) {
      newArray[foundIndex] = obj;
      return newArray;
    } else {
      return false;
    }
  }

  public static transfromGrids(grids) {
    const newGrids = Object.assign({}, grids);
    const newEntities = newGrids.entities.slice();
    newEntities.forEach((item, i) => {
      if (newEntities[i].type === 'destination') {
        newEntities[i] = Object.assign({}, newEntities[i], newEntities[i].input);
      }
    });
    newGrids.entities = newEntities;
    return newGrids;
  }
}
