import { createReducer, on } from '@ngrx/store';
import * as ClipsActions from '../actions/clips.actions';
import { DataTransform } from '../../../services/DataTransform';
import {Clip} from '../../../services/api/clips/clip.model';

export const clipsFeatureKey = 'clips';

export interface State {
  record: Clip[];
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: [],
  error: null,
  loaded: false,
  loading: false,
};

export const reducer = createReducer(
  initialState,

  on(ClipsActions.loadClips, () => ({
    record: [],
    error: null,
    loaded: false,
    loading: true,
  })),
  on(ClipsActions.loadClipsSuccess, (state, action) => ({
    record: action.data,
    error: null,
    loaded: true,
    loading: false,
  })),
  on(ClipsActions.loadClipsFailure, (state, action) => ({
    record: [],
    error: action.error,
    loaded: false,
    loading: false,
  })),
  on(ClipsActions.updateClipStatus, (state, action) => {
    const clip: Clip = action.data;
    const currentRecords: Clip[] = state.record;

    let updatedData = DataTransform
      .findObjInArrayByKeyAndReplaceIfKeyChanged(currentRecords, clip, 'id', 'status');
    updatedData = updatedData ? updatedData : currentRecords;
    return ({
      record: updatedData,
      error: null,
      loaded: true,
      loading: false,
    });
  }),
  on(ClipsActions.clearClipsState, () => ({
    record: [],
    error: null,
    loaded: false,
    loading: false,
  })),
);
