import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import {Clip, ClipRequest} from '../clips/clip.model';

@Injectable({
  providedIn: 'root'
})
export class ClipsV2Service {

  constructor(
    private readonly apiService: ApiService
  ) {}

  public create(data: ClipRequest): Observable<Clip> {
    return this.apiService.post('/clips-v2', data);
  }

  public update(id: string, data: ClipRequest): Observable<Clip> {
    return this.apiService.put(`/clips-v2/${id}`, data);
  }
}
