import { createAction, props } from '@ngrx/store';
import {ProductionInstanceCreate} from '../../../services/api/production-instances/production.instance.create.model';
import {ProductionInstance} from '../../../services/api/production-instances/production-instance.model';

export const createVmixServer = createAction(
  '[CreateVmixServer] Create Vmix Server',
  props<{ data: ProductionInstanceCreate }>()
);

export const createVmixServerSuccess = createAction(
  '[CreateVmixServer] Create Vmix Server Success',
  props<{ data: ProductionInstance }>()
);

export const createVmixServerFailure = createAction(
  '[CreateVmixServer] Create Vmix Server Failure',
  props<{ error: any }>()
);

export const clearCreateVmixServerState = createAction(
  '[CreateVmixServer] Clear Create Vmix Server State',
);
