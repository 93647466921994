import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {ApiService} from '../api.service';
import {map} from 'rxjs/operators';
import {Multiview, MultiviewRequest, MultiviewViewer} from './multiview-viewer.model';
import {HttpParams} from '@angular/common/http';
import {MultiviewView} from './multiview.view.model';

export interface MultiviewParameters {
  mode?: 'edit' | undefined;
  id?: string | undefined;
  dns?: string | undefined;
  shortName?: string | undefined;
  password?: string | undefined;
}

@Injectable({providedIn: 'root'})
export class MultiviewsService {
  public $viewers: BehaviorSubject<MultiviewViewer[] | null> = new BehaviorSubject<MultiviewViewer[] | null>(null);

  constructor(
    private readonly apiService: ApiService
  ) {
  }

  public getAll(): Observable<Multiview[]> {
    return this.apiService.get(`/multiviews`);
  }

  public getById(id: string): Observable<Multiview> {
    return this.apiService.get(`/multiviews/${id}`);
  }

  public getByShortName(dns: string, shortName: string): Observable<Multiview> {
    return this.apiService.get(`/multiviews/${dns}/${shortName}`);
  }

  public viewById(id: string, password: string | undefined = undefined ): Observable<MultiviewView> {
    let parameters: HttpParams = new HttpParams();
    if (password) {
      parameters = parameters.append('password', password);
    }
    return this.apiService.get(`/multiviews/view/${id}`, parameters);
  }

  public viewByShortName(dns: string, shortName: string, password: string | undefined = undefined): Observable<MultiviewView> {
    let parameters: HttpParams = new HttpParams();
    if (password) {
      parameters = parameters.append('password', password);
    }
    return this.apiService.get(`/multiviews/view/${dns}/${shortName}`, parameters);
  }

  public getMultiview(parameters: MultiviewParameters): Observable<Multiview | MultiviewView> {
    const id: string | undefined = parameters.id || undefined;
    const dns: string | undefined = parameters.dns || undefined;
    const shortName: string | undefined = parameters.shortName || undefined;
    const password: string | undefined = parameters.password || undefined;

    if (parameters.mode && parameters.mode === 'edit') {
      if (id) {
        return this.getById(id);
      }

      if (dns && shortName) {
        return this.getByShortName(dns, shortName);
      }

      throw new Error('Invalid multiview parameters');
    }

    if (id) {
      return this.viewById(id, password);
    }

    if (dns && shortName) {
      return this.viewByShortName(dns, shortName, password);
    }

    throw new Error('Invalid multiview parameters');
  }

  public getViewers(): Observable<MultiviewViewer[]> {
    const viewers: MultiviewViewer[] | null = this.$viewers.getValue();
    if (viewers) {
      return of(viewers);
    }

    return this.apiService.get<MultiviewViewer[]>('/multiviews/viewers')
      .pipe(
        map((response: MultiviewViewer[]) => {
          this.$viewers.next(response);
          return response;
        })
      );
  }

  public validateShortname(shortName: string): Observable<any> {
    return this.apiService.get(`/multiviews/validate/${shortName}`);
  }

  public create(data: MultiviewRequest): Observable<Multiview> {
    return this.apiService.post(`/multiviews`, data);
  }

  public update(id: string, data: MultiviewRequest): Observable<Multiview> {
    return this.apiService.put(`/multiviews/${id}`, data);
  }

  public updateViewers(viewers: MultiviewViewer[]): void {
    this.$viewers.next(viewers);
  }

  public delete(id: string): Observable<Multiview> {
    return this.apiService.delete(`/multiviews/${id}`);
  }

}
