import {createFeatureSelector, createSelector} from '@ngrx/store';
import {State} from './stream-configuration.update.reducer';
import {StreamConfiguration} from '../../../services/api/stream-configurations/stream-configuration.model';

export const updateStreamConfigurationFeatureKey = 'updateStreamConfiguration';

export const featureSelector = createFeatureSelector<State>(updateStreamConfigurationFeatureKey);

export const getStreamConfigurationUpdateRecord = createSelector(
  featureSelector,
  (state: State): StreamConfiguration => state.record
);

export const getStreamConfigurationUpdateLoaded = createSelector(
  featureSelector,
  (state: State): boolean => state.loaded
);

export const getStreamConfigurationUpdateLoading = createSelector(
  featureSelector,
  (state: State): boolean => state.loading
);

export const getStreamConfigurationUpdateError = createSelector(
  featureSelector,
  (state: State): any => state.error
);
