import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map, tap} from 'rxjs/operators';
import {of} from 'rxjs';


import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {StreamConfigurationsService} from '../../../services/api/stream-configurations/stream-configurations.service';
import {CreateStreamConfiguration} from '../../../services/api/stream-configurations/stream-configuration.create.model';
import {StreamConfiguration} from '../../../services/api/stream-configurations/stream-configuration.model';
import {
  createStreamConfiguration,
  createStreamConfigurationFailure,
  createStreamConfigurationSuccess
} from './stream-configuration.create.actions';


@Injectable()
export class StreamConfigurationCreateEffects {

  // noinspection JSUnusedGlobalSymbols
  createStreamConfiguration$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(createStreamConfiguration),
        concatMap((payload: { data: CreateStreamConfiguration }) =>
          this.streamConfigurationsService.create(payload.data)
            .pipe(
              map((data: StreamConfiguration) => createStreamConfigurationSuccess({data})),
              catchError(error => of(createStreamConfigurationFailure({error})))
            )
        )
      );
  });

  // noinspection JSUnusedGlobalSymbols
  createStreamConfigurationSuccess$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(createStreamConfigurationSuccess),
        tap(() => this.snackBar.open('Stream configuration successfully created', 'Success', {duration: 3000})),
        tap(() => this.router.navigate(['/streams']))
      );
  }, {dispatch: false});


  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly streamConfigurationsService: StreamConfigurationsService,
    private readonly snackBar: MatSnackBar
  ) {
  }

}
