import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DestinationStatus} from '../interfaces/destination-status.interface';
import {ApiService} from '../../api.service';

@Injectable({
  providedIn: 'root'
})
export class DestinationsStatusesService {

  constructor(
    private readonly apiService: ApiService
  ) {
  }

  public getStatuses(): Observable<DestinationStatus[]> {
    return this.apiService.get<DestinationStatus[]>(`/destinations/statuses`);
  }
}
