import { createAction, props } from '@ngrx/store';
import {CreateClusterPayload} from '../../../services/api/clusters/models/cluster.model';

export const createCluster = createAction(
  '[CreateCluster] Create Clusters',
  props<{ data: CreateClusterPayload }>()
);

export const createClusterSuccess = createAction(
  '[CreateCluster] Create Cluster Success',
  props<{ data: any }>()
);

export const createClusterFailure = createAction(
  '[CreateCluster] Create Cluster Failure',
  props<{ error: any }>()
);

export const clearCreateClusterState = createAction(
  '[CreateCluster] clear Create Cluster State',
);
