import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {Clip} from '../../../services/api/clips/clip.model';
import {ClipsV2Service} from '../../../services/api/clips-v2/clips.service';
import {updateClipV2} from '../actions/update-clip-v2.actions';
import {updateClipFailure, updateClipSuccess} from '../../clips/actions/update-clip.actions';


@Injectable()
export class UpdateClipV2Effects {

  // noinspection JSUnusedGlobalSymbols
  updateClip$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateClipV2),
      concatMap((payload) =>
        this.clipsService.update(payload.id, payload.data).pipe(
          map((data: Clip) => updateClipSuccess({data})),
          catchError((error: any) => of(updateClipFailure({error}))))
      )
    );
  });

  // noinspection JSUnusedGlobalSymbols
  updateClipSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateClipSuccess),
      tap(() => this.snackBar.open('Clip successfully updated', 'Success', {duration: 3000})),
      tap(() => this.router.navigate(['/clips']))
    );
  }, {dispatch: false});


  constructor(
    private readonly actions$: Actions,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly clipsService: ClipsV2Service
  ) {
  }

}
