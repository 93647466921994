import {ApiService} from '../api.service';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ProductionInstanceCreate} from './production.instance.create.model';
import {ProductionInstance} from './production-instance.model';

@Injectable({providedIn: 'root'})
export class ProductionInstancesService {

  constructor(
    private readonly apiService: ApiService
  ) {
  }

  public getAll(): Observable<ProductionInstance[]> {
    return this.apiService.get<ProductionInstance[]>('/production-instances');
  }

  public create(data: ProductionInstanceCreate): Observable<ProductionInstance> {
    return this.apiService.post<ProductionInstance>(`/production-instances`, data);
  }

  public stop(id: string): Observable<ProductionInstance> {
    return this.apiService.get<ProductionInstance>(`/production-instances/${id}/stop`);
  }

  public start(id: string): Observable<ProductionInstance> {
    return this.apiService.get<ProductionInstance>(`/production-instances/${id}/start`);
  }

  public retry(id: string): Observable<ProductionInstance> {
    return this.apiService.put<ProductionInstance>(`/production-instances/${id}/retry`);
  }

  public delete(id: string): Observable<ProductionInstance> {
    return this.apiService.delete<ProductionInstance>(`/production-instances/${id}`);
  }

}
