import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ProductionInstancesStatus} from '../../../services/api/production-instances/production-instance.model';

@Component({
  selector: 'app-production-status-container',
  template: `
    <app-production-status [status]="status"></app-production-status>`,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductionStatusContainerComponent {
  @Input() status: ProductionInstancesStatus;
}
