import { createReducer, on } from '@ngrx/store';
import {
  clearValidateStreamKeyState,
  validateStreamKey,
  validateStreamKeyFailure,
  validateStreamKeySuccess
} from './stream-key.validation.actions';

export interface State {
  record: boolean;
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: null,
  error: null,
  loaded: false,
  loading: false,
};


export const validateStreamKeyReducer = createReducer(
  initialState,

  on(validateStreamKey, (state) => ({
    ...state,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(validateStreamKeySuccess, (state, action) => ({
    record: action.data,
    error: null,
    loaded: true,
    loading: false,
  })),
  on(validateStreamKeyFailure, (state, action) => ({
    record: null,
    error: action.error,
    loaded: false,
    loading: false,
  })),
  on(clearValidateStreamKeyState, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: false,
  }))
);

