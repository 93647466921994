import { createAction, props } from '@ngrx/store';
import {Cluster} from '../../../services/api/clusters/cluster.model';

export const loadClusters = createAction(
  '[Clusters] Load Clusters'
);

export const loadClustersSuccess = createAction(
  '[Clusters] Load Clusters Success',
  props<{ data: Cluster[] }>()
);

export const updateClusterStatus = createAction(
  '[Clusters] Update Cluster Status Success',
  props<{ data: any }>()
);

export const loadClustersFailure = createAction(
  '[Clusters] Load Clusters Failure',
  props<{ error: any }>()
);

export const clearClustersState = createAction(
  '[Clusters] clear Clusters State',
);
