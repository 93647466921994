import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map} from 'rxjs/operators';
import {of} from 'rxjs';
import {StreamConfigurationsService} from '../../../services/api/stream-configurations/stream-configurations.service';
import {validateStreamKey, validateStreamKeyFailure, validateStreamKeySuccess} from './stream-key.validation.actions';


@Injectable()
export class ValidateStreamKeyEffects {

  // noinspection JSUnusedGlobalSymbols
  validateStreamKey$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(validateStreamKey),
      concatMap((payload) =>
        this.streamConfigurationsService.validateStreamKey(payload.data)
          .pipe(
            map((data: boolean) => validateStreamKeySuccess({data})),
            catchError((error: any) => of(validateStreamKeyFailure({error})))
          )
      )
    );
  });


  constructor(
    private readonly actions$: Actions,
    private readonly streamConfigurationsService: StreamConfigurationsService
  ) {
  }

}
