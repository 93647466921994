import {createFeatureSelector, createSelector} from '@ngrx/store';
import {StoreState} from '../../store.state.interface';
import {DestinationStatus} from '../../../services/api/destinations/interfaces/destination-status.interface';

export const destinationsStatusesUpdatesFeatureKey = 'destinations-statuses-updates';
export const featureSelector = createFeatureSelector<StoreState<DestinationStatus[]>>(destinationsStatusesUpdatesFeatureKey);

export const destinationsStatusesUpdatesSelector = createSelector(
  featureSelector,
  (state): DestinationStatus[] => state.data
);
