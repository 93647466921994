import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map, tap} from 'rxjs/operators';
import {of} from 'rxjs';

import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {updateStreamConfiguration, updateStreamConfigurationFailure, updateStreamConfigurationSuccess} from './stream-configuration.update.actions';
import {StreamConfigurationsService} from '../../../services/api/stream-configurations/stream-configurations.service';
import {CreateStreamConfiguration} from '../../../services/api/stream-configurations/stream-configuration.create.model';
import {StreamConfiguration} from '../../../services/api/stream-configurations/stream-configuration.model';


@Injectable()
export class StreamConfigurationUpdateEffects {

  // noinspection JSUnusedGlobalSymbols
  updateConfiguration$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateStreamConfiguration),
      concatMap((payload: { id: string, data: CreateStreamConfiguration }) => {
          const id: string = payload.id;
          const data: CreateStreamConfiguration = payload.data;
          return this.service.update(id, data)
            .pipe(
              map((data: StreamConfiguration) => updateStreamConfigurationSuccess({data: data})),
              catchError((error: any) => of(updateStreamConfigurationFailure({error: error})))
            );
        }
      )
    );
  });

  // noinspection JSUnusedGlobalSymbols
  updateConfigurationSuccess$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(updateStreamConfigurationSuccess),
        tap(() => this.snackBar.open('Stream Configuration successfully updated', 'Success', {duration: 3000})),
        tap(() => this.router.navigate(['/streams']))
      );
  }, {dispatch: false});


  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly service: StreamConfigurationsService,
    private readonly snackBar: MatSnackBar
  ) {
  }

}
