import {ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DestinationSrtTarget} from '../../../services/api/destinations/interfaces/destination.model';

@Component({
  selector: 'app-destination-srt-caller-form',
  templateUrl: './destination-srt-caller-form.component.html',
  styleUrls: ['./destination-srt-caller-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DestinationSrtCallerFormComponent implements OnChanges, OnDestroy {
  @Input() destinationControl: FormGroup;
  @Input() destination: DestinationSrtTarget;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['destinationControl'] && this.destinationControl) {
      if (this.destination) {
        this.createControls(this.destination);
      } else {
        this.createControls();
      }
    }
  }

  createControls(destination: DestinationSrtTarget = {
    protocol: 'SRT',
    address: null,
    port: null,
    passphrase: null,
    latency: 200,
    srtStreamId: null
  }) {
    const isEncrypted: boolean = !!destination.passphrase;
    this.destinationControl.addControl('address', new FormControl(destination.address, Validators.required));
    this.destinationControl.addControl('port', new FormControl(destination.port, Validators.required));
    this.destinationControl.addControl('srtStreamId', new FormControl(destination.srtStreamId));
    this.destinationControl.addControl('isEncrypted', new FormControl(isEncrypted));
    this.destinationControl.addControl('passphrase', new FormControl(destination.passphrase,
      [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(79)
      ]));
    this.destinationControl.addControl('latency', new FormControl(destination.latency,
      [
        Validators.required,
        Validators.min(120),
        Validators.max(4000)
      ]));

    this.onChangeEncryption();
  }

  onChangeEncryption() {
    const isEncrypted = this.destinationControl.get('isEncrypted').value;
    if (!isEncrypted) {
      this.destinationControl.get('passphrase').setValue('');
      this.destinationControl.get('passphrase').disable();
    } else {
      this.destinationControl.get('passphrase').enable();
    }
  }

  onPasteSrtAddress() {
    setTimeout(() => {
      let address = this.destinationControl.get('address').value;
      if (address.indexOf(':/') !== -1) {
        address = address.slice(address.indexOf(':/') + 3);
      }
      address = address.split(':');
      this.destinationControl.get('address').setValue(address[0]);
      if (address[1]) {
        this.destinationControl.get('port').setValue(Number(address[1]));
      }
    });
  }

  ngOnDestroy(): void {
    this.removeControls();
  }

  removeControls() {
    this.destinationControl.removeControl('address');
    this.destinationControl.removeControl('port');
    this.destinationControl.removeControl('isEncrypted');
    this.destinationControl.removeControl('passphrase');
    this.destinationControl.removeControl('latency');
  }

}
