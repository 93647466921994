import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map} from 'rxjs/operators';
import {of} from 'rxjs';

import {StreamConfigurationsService} from '../../../services/api/stream-configurations/stream-configurations.service';
import {loadStreamConfiguration, loadStreamConfigurationFailure, loadStreamConfigurationSuccess} from './stream-configuration.load.actions';
import {StreamConfiguration} from '../../../services/api/stream-configurations/stream-configuration.model';


@Injectable()
export class StreamConfigurationLoadEffects {

  loadStreamConfiguration$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadStreamConfiguration),
      concatMap((payload: { id: string }) =>
        this.service.getById(payload.id)
          .pipe(
            map((data: StreamConfiguration) => loadStreamConfigurationSuccess({data: data})),
            catchError(error => of(loadStreamConfigurationFailure({error: error})))
          )
      )
    );
  });


  constructor(
    private readonly actions$: Actions,
    private readonly service: StreamConfigurationsService
  ) {
  }

}
