import { createAction, props } from '@ngrx/store';

export const validateStreamKey = createAction(
  '[StreamKeyValidation] Validate Stream Key',
  props<{ data: string }>()
);

export const validateStreamKeySuccess = createAction(
  '[StreamKeyValidation] Validate Stream Key Success',
  props<{ data: boolean }>()
);

export const validateStreamKeyFailure = createAction(
  '[StreamKeyValidation] Validate Stream Key Failure',
  props<{ error: any }>()
);

export const clearValidateStreamKeyState = createAction(
  '[StreamKeyValidation] Clear Validate Stream Key State',
);
