import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromClusters from '../reducers/clusters.reducer';
import {Cluster} from '../../../services/api/clusters/cluster.model';

export const selectClustersState = createFeatureSelector<fromClusters.State>(
  fromClusters.clustersFeatureKey
);

export const getClustersRecord = createSelector(
  selectClustersState,
  (state: fromClusters.State): Cluster[] => state.record
);

export const getClustersLoaded = createSelector(
  selectClustersState,
  (state: fromClusters.State): boolean => state.loaded
);

export const getClustersLoading = createSelector(
  selectClustersState,
  (state: fromClusters.State): boolean => state.loading
);

export const getClustersError = createSelector(
  selectClustersState,
  (state: fromClusters.State): any => state.error
);
