import {StoreState} from '../../store.state.interface';
import {DestinationStatus} from '../../../services/api/destinations/interfaces/destination-status.interface';
import {createReducer, on} from '@ngrx/store';
import {updateDestinationsStatuses, updateDestinationsStatusesSuccess} from './update-destinations-statuses.actions';

export const initialState: StoreState<DestinationStatus[]> = {
  data: null,
  error: null,
  isLoaded: false
};

export const updateDestinationsStatusesReducer = createReducer(
  initialState,

  on(updateDestinationsStatuses, (state) => ({
    ...state,
    isLoaded: false
  })),
  on(updateDestinationsStatusesSuccess, (state, action) => ({
    ...state,
    data: action.data,
    isLoaded: true
  }))
);
