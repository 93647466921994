import { createReducer, on } from '@ngrx/store';
import {StreamConfiguration} from '../../../services/api/stream-configurations/stream-configuration.model';
import {
  clearUpdateStreamConfigurationState,
  updateStreamConfiguration,
  updateStreamConfigurationFailure,
  updateStreamConfigurationSuccess
} from './stream-configuration.update.actions';


export interface State {
  record: StreamConfiguration | null;
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: null,
  error: null,
  loaded: false,
  loading: false,
};


export const updateStreamConfigurationReducer = createReducer(
  initialState,

  on(updateStreamConfiguration, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(updateStreamConfigurationSuccess, (state: State, action) => ({
    record: action.data,
    error: null,
    loaded: true,
    loading: false,
  })),
  on(updateStreamConfigurationFailure, (state: State, action) => ({
    record: null,
    error: action.error,
    loaded: false,
    loading: false,
  })),
  on(clearUpdateStreamConfigurationState, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: false,
  }))
);
