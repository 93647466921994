<div [formGroup]="destinationControl" class="form-block" *ngIf="destinationControl && authSchemes">
  <div class="rtmp-url-block">
    <mat-form-field class="protocol">
      <mat-select value="RTMP" formControlName="protocol" (selectionChange)="onChangeProtocol()">
        <mat-option [value]="'RTMP'">rtmp://</mat-option>
        <mat-option [value]="'RTMPS'">rtmps://</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Stream URL filed -->
    <mat-form-field class="url">
      <mat-label>Stream URL
        <app-required-field [validator]="destinationControl.controls.streamUrl.validator"></app-required-field>
      </mat-label>
      <input matInput autocomplete="off" placeholder="Stream URL" type="text" formControlName="streamUrl"
             (paste)="onPasteRtmpStreamUrl()">
      <mat-error *ngIf="destinationControl.controls.streamUrl.invalid &&
              (destinationControl.controls.streamUrl.dirty ||
              destinationControl.controls.streamUrl.touched)">
        Property is always required and can`t be empty
      </mat-error>
    </mat-form-field>

    <mat-form-field class="port">
      <input matInput autocomplete="off" placeholder="port" type="number" formControlName="port">
    </mat-form-field>
  </div>

  <!-- Stream key field -->
  <mat-form-field>
    <mat-label>Stream Key
      <app-required-field [validator]="destinationControl.controls.streamKey.validator"></app-required-field>
    </mat-label>
    <input appInputFilter [controlRef]="destinationControl.controls.streamKey"
           [filterSpaceAtStart]="true" [filterSpaces]="true"
           matInput autocomplete="off" placeholder="Stream Key" type="text" formControlName="streamKey">
    <mat-error *ngIf="destinationControl.controls.streamKey.invalid &&
              (destinationControl.controls.streamKey.dirty ||
              destinationControl.controls.streamKey.touched)">
      Property is always required and can`t be empty
    </mat-error>
  </mat-form-field>

  <!-- Authorization schema selector -->
  <mat-form-field>
    <mat-label>Authorization Schema
      <app-required-field [validator]="destinationControl.controls.authScheme.validator"></app-required-field>
    </mat-label>
    <mat-select formControlName="authScheme" (selectionChange)="onChangeScheme()">
      <mat-option *ngFor="let scheme of authSchemes" [value]="scheme.name">
        {{scheme.label}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div *ngIf="selectedScheme?.auth">

    <!-- Username field -->
    <mat-form-field>
      <mat-label>Username
        <app-required-field [validator]="destinationControl.controls.username.validator"></app-required-field>
      </mat-label>
      <input matInput autocomplete="off" placeholder="Username" type="text" formControlName="username">
    </mat-form-field>

    <!-- Password field -->
    <mat-form-field>
      <mat-label>Password
        <app-required-field [validator]="destinationControl.controls.password.validator"></app-required-field>
      </mat-label>
      <input matInput autocomplete="off" placeholder="Password" type="text" formControlName="password">
    </mat-form-field>
  </div>

  <!-- AKAMAI Stream ID -->
  <div *ngIf="selectedScheme?.streamId">
    <mat-form-field>
      <mat-label>Stream ID
        <app-required-field [validator]="destinationControl.controls.akamaiStreamId.validator"></app-required-field>
      </mat-label>
      <input matInput autocomplete="off" placeholder="Stream ID" type="number" formControlName="akamaiStreamId">
    </mat-form-field>
  </div>
</div>
