import { createAction, props } from '@ngrx/store';
import {UUID} from 'crypto';

export const stopRecord = createAction(
  '[StopRecord] Stop Record',
  props<{ recordId: UUID }>()
);

export const stopRecordSuccess = createAction(
  '[StopRecord] Stop Record Success',
  props<{ data: any }>()
);

export const stopRecordFailure = createAction(
  '[StopRecord] Stop Record Failure',
  props<{ error: any }>()
);

export const clearStopRecordState = createAction(
  '[Records] clear Stop Record State',
);
