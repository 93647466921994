import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as ClustersActions from '../actions/clusters.actions';
import { ClustersService } from '../../../services/api/clusters/clusters.service';



@Injectable()
export class ClustersEffects {

  loadClusters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ClustersActions.loadClusters),
      concatMap(() =>
        this.clustersService.getClusters().pipe(
          map(data => ClustersActions.loadClustersSuccess({ data })),
          catchError(error => of(ClustersActions.loadClustersFailure({ error }))))
      )
    );
  });



  constructor(private actions$: Actions,
              private clustersService: ClustersService) {}

}
