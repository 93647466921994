import { createAction, props } from '@ngrx/store';
import {StreamConfiguration} from '../../../services/api/stream-configurations/stream-configuration.model';
import {StreamConfigurationsRequestParameters} from '../../../services/api/stream-configurations/stream-configurations.service';

export const loadStreamConfigurations = createAction(
  '[StreamConfigurations] Load Stream Configurations',
  props<StreamConfigurationsRequestParameters>()
);

export const loadStreamConfigurationsSuccess = createAction(
  '[StreamConfigurations] Load Stream Configurations Success',
  props<{ data: StreamConfiguration[]}>()
);

export const loadStreamConfigurationsFailure = createAction(
  '[StreamConfigurations] Load Stream Configurations Failure',
  props<{ error: any }>()
);

export const clearStreamConfigurationsState = createAction(
  '[StreamConfigurations] Clear Stream Configurations State',
);
