import { createAction, props } from '@ngrx/store';
import {CreateStreamConfiguration} from '../../../services/api/stream-configurations/stream-configuration.create.model';
import {StreamConfiguration} from '../../../services/api/stream-configurations/stream-configuration.model';

export const createStreamConfiguration = createAction(
  '[CreateStreamConfiguration] Create Stream Configuration',
  props<{ data: CreateStreamConfiguration }>()
);

export const createStreamConfigurationSuccess = createAction(
  '[CreateStreamConfiguration] Create Stream Configuration Success',
  props<{ data: StreamConfiguration }>()
);

export const createStreamConfigurationFailure = createAction(
  '[CreateStreamConfiguration] Create Stream Configuration Failure',
  props<{ error: any }>()
);

export const clearCreateStreamConfigurationState = createAction(
  '[CreateStreamConfiguration] clear Create Stream Configuration State',
);
