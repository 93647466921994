import { createFeatureSelector, createSelector } from '@ngrx/store';
import {State} from './stream-configuration.create.reducer';
import {StreamConfiguration} from '../../../services/api/stream-configurations/stream-configuration.model';

export const createStreamConfigurationFeatureKey = 'createStreamConfiguration';

export const featureSelector = createFeatureSelector<State>(createStreamConfigurationFeatureKey);

export const getCreatedConfigurationRecord = createSelector(
  featureSelector,
  (state: State): StreamConfiguration => state.record
);

export const getCreatedConfigurationLoaded = createSelector(
  featureSelector,
  (state: State): boolean => state.loaded
);

export const getCreatedConfigurationLoading = createSelector(
  featureSelector,
  (state: State): boolean => state.loading
);

export const getCreatedConfigurationError = createSelector(
  featureSelector,
  (state: State): any => state.error
);
