import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {noop, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {WebStorageService} from '../web-storage.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {environment} from '../../../environments/environment';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private readonly baseUrl: string = environment.baseUrl;

  constructor(
    private readonly router: Router,
    private readonly storageService: WebStorageService,
    private readonly snackBar: MatSnackBar
  ) {
  }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(
        tap(noop, (error) => {
          if (error instanceof HttpErrorResponse) {
            const endpoint: string = this.getEndpoint(request);
            const statusCode: number = error.status;

            if (statusCode === 401) {
              const message = error?.error?.status?.message || undefined;
              if (message !== undefined && message === 'Invalid credentials') {
                this.snackBar.open(message, 'Error', {duration: 3000, panelClass: 'notify-error'});
              }

              this.storageService.deleteItem('token');
              this.router.navigate(['/login'])
                .catch((error) => console.error('Navigate to login page error:', error));

              return;
            }

            if (statusCode >= 400 && statusCode < 500 && statusCode !== 424) {
              if (endpoint === '/auth/oauth/credentials' && statusCode === 404) {
                return;
              }

              const message = this.getErrorMessage(error) || 'Request failed.';
              this.snackBar.open(message, 'Error', {duration: 3000, panelClass: 'notify-error'});

              return;
            }

            if (statusCode >= 500) {
              const message = this.getErrorMessage(error) || 'Server error occurred. Please try again later.';
              if (message !== 'Stream name already taken') {
                this.snackBar.open(message, 'Error', {duration: 3000, panelClass: 'notify-error'});
              }

              return;
            }
          }
        }));
  }

  private getEndpoint(request: HttpRequest<unknown>): string {
    const url: string = request.url;
    return url.replace(this.baseUrl, '');
  }

  private getErrorMessage(error: HttpErrorResponse): string | undefined {
    if (error && error.error && error.error.status && error.error.status.message) {
      return error.error.status.message;
    }

    return undefined;
  }
}
