import { createFeatureSelector, createSelector } from '@ngrx/store';
import {State} from './stream-configurations.load-all.reducer';
import {StreamConfiguration} from '../../../services/api/stream-configurations/stream-configuration.model';

export const loadAllStreamConfigurationFeatureKey = 'streamConfigurations';
export const getStreamConfigurationsSelector = createFeatureSelector<State>(loadAllStreamConfigurationFeatureKey);

export const getStreamConfigurationsRecord = createSelector(
  getStreamConfigurationsSelector,
  (state: State): StreamConfiguration[] => state.record
);

export const getStreamConfigurationsLoaded = createSelector(
  getStreamConfigurationsSelector,
  (state: State): boolean => state.loaded
);

export const getStreamConfigurationsLoading = createSelector(
  getStreamConfigurationsSelector,
  (state: State): boolean => state.loading
);

export const getStreamConfigurationsError = createSelector(
  getStreamConfigurationsSelector,
  (state: State): any => state.error
);
