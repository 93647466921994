import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map, tap} from 'rxjs/operators';
import {of} from 'rxjs';

import * as DeleteClusterActions from '../actions/delete-cluster.actions';
import {ClustersService} from '../../../services/api/clusters/clusters.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as ClustersActions from '../../clusters/actions/clusters.actions';
import {DeleteClusterPayload} from '../../../services/api/clusters/cluster.model';


@Injectable()
export class DeleteClusterEffects {

  // noinspection JSUnusedGlobalSymbols
  deleteCluster$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DeleteClusterActions.deleteCluster),
      concatMap((payload: { data: DeleteClusterPayload }) =>
        this.clustersService.deleteCluster(payload.data)
          .pipe(
            map(data => DeleteClusterActions.deleteClusterSuccess({data})),
            catchError(error => of(DeleteClusterActions.deleteClusterFailure({error})))
          )
      )
    );
  });

  // noinspection JSUnusedGlobalSymbols
  deleteClusterSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DeleteClusterActions.deleteClusterSuccess),
      tap(() => this.snackBar.open('Cluster deletion successfully started', 'Success', {duration: 3000})),
      map(() => ClustersActions.loadClusters())
    );
  });


  constructor(
    private readonly actions$: Actions,
    private readonly clustersService: ClustersService,
    private readonly snackBar: MatSnackBar
  ) {
  }

}
