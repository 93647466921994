import {AfterViewInit, Directive, Input, OnDestroy} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {Subscription} from 'rxjs';

@Directive({
  selector: '[appInputFilter]'
})
export class InputFilterDirective implements AfterViewInit, OnDestroy {
  @Input() controlRef: AbstractControl;
  @Input() filterSpaceAtStart = false;
  @Input() filterSpaces = false;
  @Input() filterTags = false;
  @Input() onlyLetters = false;
  @Input() onlyLettersUnderscoresSlashes = false;
  @Input() onlyLettersNumbersSlashes = false;
  @Input() onlyLettersNumbersSlashesUnderscoresSpaces = false;
  @Input() fileName = false;
  private valueChangesSubscription: Subscription;

  public ngAfterViewInit(): void {
    this.valueChangesSubscription = this.controlRef.valueChanges.subscribe(() => this.filterOut());
  }

  public ngOnDestroy(): void {
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
    }
  }

  private filterOut(): void {
    const value: string | null = this.controlRef.value;
    if (!value) {
      return;
    }
    if (this.filterTags) {
      const newValue = value.replace(/(<([^>]+)>)/gi, '');
      if (value !== newValue) {
        this.controlRef.setValue(newValue, {emitEvent: false});
      }
    }
    if (this.filterSpaceAtStart) {
      const newValue = value.substring(1);
      if (value.startsWith(' ') && value !== newValue) {
        this.controlRef.setValue(newValue, {emitEvent: false});
      }
    }
    if (this.filterSpaces) {
      const newValue = value.replace(/\s/g, '');
      if (value !== newValue) {
        this.controlRef.setValue(value.replace(/\s/g, ''), {emitEvent: false});
      }
    }
    if (this.onlyLetters) {
      const newValue = value.replace(/[^a-zA-Z]+/g, '');
      if (value !== newValue) {
        this.controlRef.setValue(newValue, {emitEvent: false});
      }
    }
    if (this.onlyLettersUnderscoresSlashes) {
      const newValue = value.replace(/[^a-zA-Z_-]+/g, '');
      if (value !== newValue) {
        this.controlRef.setValue(newValue, {emitEvent: false});
      }
    }
    if (this.onlyLettersNumbersSlashes) {
      const newValue = value.replace(/[^a-zA-Z0-9-]+/g, '');
      if (value !== newValue) {
        this.controlRef.setValue(newValue, {emitEvent: false});
      }
    }
    if (this.onlyLettersNumbersSlashesUnderscoresSpaces) {
      const newValue = value.replace(/[^a-zA-Z0-9-_\s]+/g, '');
      if (value !== newValue) {
        this.controlRef.setValue(newValue, {emitEvent: false});
      }
    }
    if (this.fileName) {
      const newValue = value.replace(/[:";\\/*?$|]/g, '');
      if (value !== newValue) {
        this.controlRef.setValue(newValue, {emitEvent: false});
      }
    }
  }
}
