import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, concatMap} from 'rxjs/operators';
import {of} from 'rxjs';

import * as VmixServersActions from '../actions/vmix-servers.actions';
import {ProductionInstancesService} from '../../../services/api/production-instances/production-instances.service';

@Injectable()
export class VmixServersEffects {

  constructor(
    private readonly actions: Actions,
    private readonly productionInstancesService: ProductionInstancesService
  ) {}

  loadProductionInstances = createEffect(() => {
    return this.actions.pipe(
      ofType(VmixServersActions.loadVmixServers),
      concatMap(() =>
        this.productionInstancesService.getAll()
          .pipe(
            map(instances => {
              return VmixServersActions.loadVmixServersSuccess({data: instances});
            }),
            catchError(error => of(VmixServersActions.loadVmixServersFailure({error})))
          )
      )
    );
  });

}
