import { createAction, props } from '@ngrx/store';
import {ProductionInstance} from '../../../services/api/production-instances/production-instance.model';

export const stopVmixServer = createAction(
  '[StopVmixServer] Stop Vmix Server',
  props<{ data: string }>()
);

export const stopVmixServerSuccess = createAction(
  '[StopVmixServer] Stop Vmix Server Success',
  props<{ data: ProductionInstance }>()
);

export const stopVmixServerFailure = createAction(
  '[StopVmixServer] Stop Vmix Server Failure',
  props<{ error: any }>()
);

export const clearStopVmixServerState = createAction(
  '[StopVmixServer] Clear Stop Vmix Server State',
);
