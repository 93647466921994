import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {DynamicScriptLoaderService} from '../../../services/dynamic-script-loader/dynamic-script-loader.service';
import {GridOverlays} from '../../../models/grid.interface';
import {
  SLDP as SLDPPlayerScript,
  SLDPPlayer,
  SLDPPlayerCallbacks,
  SLDPPlayerCurrentStream,
  SLDPPlayerParameters
} from './sldp-player.typings';
import {fromEvent, Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {MultiviewViewEntity} from '../../../services/api/multiviews/multiview.view.model';

declare const SLDP: SLDPPlayerScript;

@Component({
  selector: 'app-sldp-player',
  templateUrl: './sldp-player.component.html',
  styleUrls: ['./sldp-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SldpPlayerComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('video') video: { nativeElement: { clientHeight: number; }; };
  @Input() index = 1;
  @Input() sizes = {width: 720, height: 405};
  @Input() multiviewEntity: MultiviewViewEntity | any;
  @Input() gridOverlays: GridOverlays;
  @Input() isUseNativeControls = false;
  @Input() isEnabledVU: boolean;
  @Input() isDisplayExpandIcon = false;
  @Input() isDisplaySoundIcon = true;
  @Input() latency: number = 500;
  public playerVisibility = 'hidden';
  public currentStream: SLDPPlayerCurrentStream;
  public isMuted = true;
  public isShowSoundSlider = false;
  public VUUpdatedValues: number[];
  public VUIndicatorQty: number[] = [];
  public VUHeight: number;
  public isConnected = false;
  public volume = 0;
  private sldpPlayer: SLDPPlayer;
  private intervalId: NodeJS.Timeout | undefined = undefined;
  private isScriptLoaded = false;
  private hideSliderTimeoutId: NodeJS.Timeout | undefined = undefined;
  private fullscreenExitSubscription: Subscription | undefined = undefined;

  constructor(
    private dynamicScriptLoader: DynamicScriptLoaderService,
    private cdr: ChangeDetectorRef
  ) {
  }

  public ngOnInit(): void {
    if (!this.isScriptLoaded) {
      void this.loadPlayerScript();
    }
    this.subscribeToFullScreenExit();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['isEnabledVU'] && this.multiviewEntity) {
      this.initPlayer();
    }
    if (changes['latency'] && this.multiviewEntity) {
      this.setLatency();
    }
  }

  public toggleSound(): void {
    this.isMuted = !this.isMuted;
    const volumeSliderPosition: number = this.getVolumeSliderPosition();
    this.isShowSoundSlider = !this.isMuted;
    this.sldpPlayer.setVolume(volumeSliderPosition);
    this.volume = volumeSliderPosition;
  }

  public onExpand(): void {
    const player: HTMLElement = document.getElementById('player-' + this.index);
    const video: Element = player.getElementsByClassName('sldp_player_wrp_video')[0];
    video.requestFullscreen()
      .catch(error => console.error('Request full screen error', error));
  }

  public onChangeVolume(currentVolume: number): void {
    this.volume = currentVolume;
    this.isMuted = this.volume === 0;
    this.sldpPlayer.setVolume(this.volume);
  }

  public onMouseLeave(): void {
    if (!this.isMuted) {
      this.hideSliderTimeoutId = setTimeout(() => this.isShowSoundSlider = false, 2000);
    }
  }

  public onMouseEnter(): void {
    if (!this.isMuted) {
      clearTimeout(this.hideSliderTimeoutId);
      this.isShowSoundSlider = true;
    }
  }

  public ngOnDestroy(): void {
    clearInterval(this.intervalId);
    if (this.sldpPlayer) {
      this.sldpPlayer.destroy();
    }
    if (this.fullscreenExitSubscription !== undefined) {
      this.fullscreenExitSubscription.unsubscribe();
    }
  }

  public formatBitrate(): number {
    const bandwidth: number = this.sldpPlayer.getCurrentStreamBandwidth();
    return Math.trunc(bandwidth / 1000);
  }

  public isShowNotConfiguredMessage(): boolean {
    const isInputEmpty: boolean = this.multiviewEntity === undefined;
    const isInputFieldsEmpty: boolean = !this.multiviewEntity?.url;
    return isInputEmpty || isInputFieldsEmpty;
  }

  public isShowOverlay(): boolean {
    const isNameOverlay: boolean = this.gridOverlays?.inputNameOverlay === true;
    const isAddressOverlay: boolean = this.gridOverlays?.inputAddressOverlay === true;
    const isShowNotConfiguredMessage: boolean = this.isShowNotConfiguredMessage();
    return isNameOverlay || isAddressOverlay || isShowNotConfiguredMessage;
  }

  private setLatency(): void {
    this.sldpPlayer.setParameters({
      latency_tolerance: this.latency
    });
  }

  private subscribeToFullScreenExit(): void {
    this.fullscreenExitSubscription = fromEvent(document, 'fullscreenchange')
      .pipe(
        filter(() => document.fullscreenElement === null)
      )
      .subscribe(() => this.initPlayer());
  }

  private mute(): void {
    this.isMuted = true;
    this.isShowSoundSlider = false;
    this.sldpPlayer.setVolume(0);
    this.volume = 0;
  }

  private async loadPlayerScript(): Promise<void> {
    try {
      await this.dynamicScriptLoader.loadScript('sldp-player');
      this.isScriptLoaded = true;
      this.initPlayer();
    } catch (error) {
      console.error('Load SLDP player script error:', error);
    }
  }

  private updateVolumeUnitsIndicator(decibels: number[]): void {
    if (decibels) {
      this.VUUpdatedValues = decibels.slice();
      this.cdr.markForCheck();
      if (this.VUIndicatorQty.length !== this.VUUpdatedValues.length) {
        this.VUIndicatorQty = Array(this.VUUpdatedValues.length).fill(0);
      }
    }
  }

  private createSldpPlayer(): void {
    console.log('Creating SLDP player');
    console.log('Multiview entity:', this.multiviewEntity);
    const container = `player-${this.index}`;
    const streamUrl: string = this.multiviewEntity.url;
    const muted: boolean = this.isMuted;
    const parameters: SLDPPlayerParameters = {
      container,
      stream_url: streamUrl,
      autoplay: true,
      muted: muted,
      width: 'parent',
      height: 'parent',
      initial_resolution: '320',
      buffering: 1000,
      latency_tolerance: this.latency,
      controls: this.isUseNativeControls,
      latency_adjust_method: 'seek',
      reconnects: 999999
    };

    if (this.isEnabledVU) {
      parameters.vu_meter = {
        mode: 'peak',
        type: 'input'
      };
    }

    this.sldpPlayer = SLDP.init(parameters);
    if (this.isMuted === true) {
      this.mute();
    }
  }

  private setCurrentStream(): void {
    this.currentStream = this.sldpPlayer.getCurrentStream();
    this.cdr.markForCheck();
  }

  private onVUMeterUpdate(decibels: number[]): void {
    this.updateVolumeUnitsIndicator(decibels);
  }

  private onError(error: string): void {
    this.isConnected = false;
    if (error === 'NO_PLAYABLE_SOURCE_FOUND') {
      setTimeout(() => this.sldpPlayer.play(), 10000);
    }
  }

  private onConnectionEstablished(): void {
    this.isConnected = true;
    this.cdr.markForCheck();
    if (this.sldpPlayer && this.gridOverlays && this.gridOverlays.bitrateOverlay) {
      this.intervalId = setInterval(() => this.setCurrentStream(), 1000);
    }
  }

  private initPlayer(): void {
    if (this.sldpPlayer) {
      this.sldpPlayer.destroy();
    }
    this.createSldpPlayer();

    const callbacks: Partial<SLDPPlayerCallbacks> = {
      onVUMeterUpdate: (magnitudes: number[], decibels: number[]) => this.onVUMeterUpdate(decibels),
      onConnectionEstablished: () => this.onConnectionEstablished(),
      onError: (error: string) => this.onError(error)
    };

    this.sldpPlayer.setCallbacks(callbacks);

    this.playerVisibility = 'visible';
    this.VUHeight = this.video.nativeElement.clientHeight;
    this.cdr.markForCheck();
  }

  private getVolumeSliderPosition(): number {
    if (this.isMuted) {
      return 0;
    }
    if (this.volume === 0) {
      return 50;
    } else {
      return this.volume;
    }
  }

}
