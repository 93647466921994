import { createReducer, on } from '@ngrx/store';
import * as VmixServersActions from '../actions/vmix-servers.actions';
import {ProductionInstance} from '../../../services/api/production-instances/production-instance.model';

export const vmixServersFeatureKey = 'vmixServers';

export interface State {
  record: readonly ProductionInstance[];
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: [],
  error: null,
  loaded: false,
  loading: false,
};


export const reducer = createReducer(
  initialState,

  on(VmixServersActions.loadVmixServers, (state) => ({
    ...state,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(VmixServersActions.loadVmixServersSuccess, (state, action) => ({
    record: action.data,
    error: null,
    loaded: true,
    loading: false,
  })),
  on(VmixServersActions.updateProductionInstanceSuccess, (state, action) => {
    const instancesList: ProductionInstance[] = state.record.slice();
    const instance: ProductionInstance = action.data;
    const index: number = instancesList.findIndex( item => item.id === instance.id );
    instancesList[index] = instance;
    return {
      record: instancesList,
      error: null,
      loaded: true,
      loading: false,
    };
  }),
  on(VmixServersActions.deleteProductionInstanceSuccess, (state, action) => {
    const instancesList: ProductionInstance[] = state.record.slice();
    const instanceId: string = action.data;
    const index: number = instancesList.findIndex( item => item.id === instanceId );
    if (index !== -1) {
      instancesList.splice(index, 1);
    }
    return {
      record: instancesList,
      error: null,
      loaded: true,
      loading: false,
    };
  }),
  on(VmixServersActions.loadVmixServersFailure, (state, action) => ({
    record: [],
    error: action.error,
    loaded: false,
    loading: false,
  })),
  on(VmixServersActions.clearVmixServersState, () => ({
    record: [],
    error: null,
    loaded: false,
    loading: false,
  })),
);
