import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as StopRecordActions from '../actions/stop-record.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DvrService } from '../../../services/api/dvr/dvr.service';
import * as RecordsActions from '../actions/records.actions';



@Injectable()
export class StopRecordEffects {

  stopRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(StopRecordActions.stopRecord),
      concatMap((payload) =>
        this.dvrService.stopRecord(payload.recordId).pipe(
          map(data => StopRecordActions.stopRecordSuccess({ data })),
          catchError(error => of(StopRecordActions.stopRecordFailure({ error }))))
      )
    );
  });

  stopRecordSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(StopRecordActions.stopRecordSuccess),
      tap(() => this.snackBar.open('Recording stopped successfully', 'Success', {duration: 3000})),
      map(() => RecordsActions.loadRecords()),
    );
  });



  constructor(private actions$: Actions,
              private snackBar: MatSnackBar,
              private dvrService: DvrService) {}

}
