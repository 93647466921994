import {ApiService} from '../../api.service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {NdiInput} from './ndi-input.model';

@Injectable({
  providedIn: 'root'
})
export class NdiInputsService {

  constructor(
    private readonly apiService: ApiService
  ) {
  }

  public getAll(instanceId: string): Observable<NdiInput[]> {
    return this.apiService.get<NdiInput[]>(`/instance/ndi/${instanceId}`);
  }
}
