import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Destination} from '../../../../../services/api/destinations/destination.model';
import {InstanceRole} from '../../../../../services/api/streams/streams.service';
import {StreamConfiguration} from '../../../../../services/api/stream-configurations/stream-configuration.model';
import {Cluster} from '../../../../../services/api/clusters/cluster.model';
import {MultiviewEntity} from '../../../../../services/api/multiviews/multiview-viewer.model';

export interface SelectedEntity {
  type: 'stream' | 'destination';
  entityId: string;
  inputId: string;
  clusterId: string;
  instanceRole: InstanceRole;
}

export type SelectEntityMode = 'all' | 'streams';


@Component({
  selector: 'app-entity-select-form',
  templateUrl: './entity-select-form.component.html',
  styleUrls: ['./entity-select-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntitySelectFormComponent {
  @Input() public mode: SelectEntityMode;
  @Input() index: number;
  @Input() entityControl: any;
  @Input() entity: MultiviewEntity;
  @Input() validationRequired: boolean;
  @Input() streamConfigurations: StreamConfiguration[] | null = null;
  @Input() streamConfigurationsLoading: boolean = true;
  @Input() clusters: Cluster[] | null = null;
  @Input() clustersLoading: boolean = true;
  @Input() destinations: Destination[];
  @Input() destinationsLoading: boolean;
  @Output() changeEntity: EventEmitter<SelectedEntity> = new EventEmitter<SelectedEntity>();
  @Output() mouseEnter: EventEmitter<any> = new EventEmitter();

  public onChangeType(): void {
    this.entityControl.removeControl('type');
    this.entityControl.removeControl('order');
    this.entityControl.removeControl('entityId');
    this.entityControl.removeControl('clusterId');
    this.entityControl.removeControl('role');
    this.entityControl.removeControl('currentEntity');
  }

  public onEntityChange(entity: SelectedEntity): void {
    this.changeEntity.emit(entity);
  }
}
