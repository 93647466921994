import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ProductionInstancesStatus} from '../../../../../services/api/production-instances/production-instance.model';

@Component({
  selector: 'app-production-status',
  templateUrl: './production-status.component.html',
  styleUrls: ['./production-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductionStatusComponent {
  @Input() status: ProductionInstancesStatus;
}
