import { createReducer, on } from '@ngrx/store';
import * as ClusterActions from '../actions/cluster.actions';
import {Cluster} from '../../../services/api/clusters/cluster.model';

export const clusterFeatureKey = 'cluster';

export interface State {
  record: Cluster;
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: null,
  error: null,
  loaded: false,
  loading: false,
};


export const reducer = createReducer(
  initialState,

  on(ClusterActions.loadCluster, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(ClusterActions.loadClusterSuccess, (state, action) => ({
    record: action.data,
    error: null,
    loaded: true,
    loading: false,
  })),
  on(ClusterActions.loadClusterFailure, (state, action) => ({
    record: null,
    error: action.error,
    loaded: false,
    loading: false,
  })),
  on(ClusterActions.clearClusterState, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: false,
  })),
);

