import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map, tap} from 'rxjs/operators';
import {of} from 'rxjs';

import {MatSnackBar} from '@angular/material/snack-bar';
import {StreamConfigurationsService} from '../../../services/api/stream-configurations/stream-configurations.service';
import {
  deleteStreamConfiguration,
  deleteStreamConfigurationFailure,
  deleteStreamConfigurationSuccess
} from './stream-configuration.delete.actions';
import {loadStreamConfigurations} from '../load-all/stream-configurations.load-all.actions';


@Injectable()
export class StreamConfigurationDeleteEffects {

  // noinspection JSUnusedGlobalSymbols
  deleteStreamConfiguration$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteStreamConfiguration),
      concatMap((payload: { id: string }) =>
        this.service.delete(payload.id)
          .pipe(
            map(() => deleteStreamConfigurationSuccess({id: payload.id})),
            catchError(error => of(deleteStreamConfigurationFailure({error})))
          )
      )
    );
  });

  // noinspection JSUnusedGlobalSymbols
  deleteStreamConfigurationSuccess$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(deleteStreamConfigurationSuccess),
        tap(() => this.snackBar.open('Stream configuration successfully deleted', 'Success', {duration: 3000})),
        map(() => loadStreamConfigurations({}))
      );
  });


  constructor(
    private readonly actions$: Actions,
    private readonly service: StreamConfigurationsService,
    private readonly snackBar: MatSnackBar
  ) {
  }

}
