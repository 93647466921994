import {createFeatureSelector, createSelector} from '@ngrx/store';
import {State} from './stream-configuration.delete.reducer';
import {StreamConfiguration} from '../../../services/api/stream-configurations/stream-configuration.model';

export const deleteStreamConfigurationFeatureKey = 'deleteStreamConfiguration';
export const featureSelector = createFeatureSelector<State>(deleteStreamConfigurationFeatureKey);

export const getDeleteStreamConfigurationRecord = createSelector(
  featureSelector,
  (state: State): StreamConfiguration => state.record
);

export const getDeleteStreamConfigurationLoaded = createSelector(
  featureSelector,
  (state: State): boolean => state.loaded
);

export const getDeleteStreamConfigurationLoading = createSelector(
  featureSelector,
  (state: State): boolean => state.loading
);

export const getDeleteStreamConfigurationError = createSelector(
  featureSelector,
  (state: State): any => state.error
);
