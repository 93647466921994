import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map} from 'rxjs/operators';
import {of} from 'rxjs';
import {DestinationsStatusesService} from '../../../services/api/destinations/statuses/destinations.statuses.service';
import {loadDestinationsStatues, loadDestinationsStatusesFailure, loadDestinationsStatusesSuccess} from './destinations-statuses.actions';
import {DestinationStatus} from '../../../services/api/destinations/interfaces/destination-status.interface';


@Injectable()
export class DestinationsStatusesEffects {

  // noinspection JSUnusedGlobalSymbols
  loadDestinationsStatuses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadDestinationsStatues),
      concatMap(() =>
        this.destinationsStatuesService.getStatuses()
          .pipe(
            map((data: DestinationStatus[]) => loadDestinationsStatusesSuccess({data})),
            catchError((error: any) => of(loadDestinationsStatusesFailure({error})))
          )
      )
    );
  });


  constructor(
    private readonly actions$: Actions,
    private readonly destinationsStatuesService: DestinationsStatusesService
  ) {
  }

}
