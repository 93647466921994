import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import {Observable} from 'rxjs';
import {DestinationRequest, DestinationUpdate} from './interfaces/destination.model';

@Injectable({
  providedIn: 'root'
})
export class DestinationsService {

  constructor(private apiService: ApiService) {}

  getDestinations(): Observable<any> {
    return this.apiService.get(`/destinations`);
  }

  getDestination(destinationId): Observable<any> {
    return this.apiService.get(`/destinations/${destinationId}`);
  }

  public createDestination(data: DestinationRequest): Observable<any> {
    return this.apiService.post(`/destinations`, data);
  }

  public updateDestination(update: DestinationUpdate): Observable<any> {
    const id: string = update.id;
    const data: DestinationRequest = update.data;
    return this.apiService.put(`/destinations/${id}`, data);
  }

  deleteDestination(destinationId): Observable<any> {
    return this.apiService.delete(`/destinations/${destinationId}`);
  }

  startDestination(destinationId): Observable<any> {
    return this.apiService.get(`/destinations/resume/${destinationId}`);
  }

  stopDestination(destinationId): Observable<any> {
    return this.apiService.get(`/destinations/stop/${destinationId}`);
  }
}
