import { Injectable } from "@angular/core";
import {ApiService} from '../api.service';
import {StreamConfigurationOrderBy} from '../streams/types/stream-configuration.order-by.type';
import {StreamConfigurationOrderDirection} from '../streams/types/stream-configuration.order.direction.type';
import {Observable} from 'rxjs';
import {StreamConfiguration} from './stream-configuration.model';
import {CreateStreamConfiguration} from './stream-configuration.create.model';

export interface StreamConfigurationsRequestParameters {
  take?: number | undefined;
  skip?: number | undefined;
  orderBy?: StreamConfigurationOrderBy | undefined;
  orderDirection?: StreamConfigurationOrderDirection | undefined;
  filter?: string | undefined;
}

@Injectable({
  providedIn: 'root'
})
export class StreamConfigurationsService {

  constructor(
    private apiService: ApiService
  ) {
  }

  public getAll(
    take: number | undefined = undefined,
    skip: number | undefined = undefined,
    orderBy: StreamConfigurationOrderBy | undefined = undefined,
    orderDirection: StreamConfigurationOrderDirection | undefined = undefined,
    filter: string | undefined = undefined
  ): Observable<StreamConfiguration[]> {
    const parameters: StreamConfigurationsRequestParameters = {};

    if (take) {
      parameters.take = take;
    }

    if (skip) {
      parameters.skip = skip;
    }

    if (orderDirection && orderBy) {
      parameters.orderBy = orderBy;
      parameters.orderDirection = orderDirection;
    }

    if (filter) {
      parameters.filter = filter;
    }

    return this.apiService.get<StreamConfiguration[]>('/stream-configurations', parameters);
  }

  public getById(id: string): Observable<StreamConfiguration> {
    return this.apiService.get<StreamConfiguration>(`/stream-configurations/${id}`);
  }

  public create(data: CreateStreamConfiguration): Observable<StreamConfiguration> {
    return this.apiService.post<StreamConfiguration>('/stream-configurations', data);
  }

  public update(id: string, data: CreateStreamConfiguration): Observable<StreamConfiguration> {
    return this.apiService.put<StreamConfiguration>(`/stream-configurations/${id}`, data);
  }

  public delete(id: string): Observable<StreamConfiguration> {
    return this.apiService.delete<StreamConfiguration>(`/stream-configurations/${id}`);
  }
}
