import { createFeatureSelector, createSelector } from '@ngrx/store';
import {State} from './stream-key.validation.reducer';

export const validateStreamKeyFeatureKey = 'validateStreamKey';

export const selectValidateStreamKeyState = createFeatureSelector<State>(
  validateStreamKeyFeatureKey
);

export const getValidateStreamKeyRecord = createSelector(
  selectValidateStreamKeyState,
  (state: State): boolean => state.record
);

export const getValidateStreamKeyLoaded = createSelector(
  selectValidateStreamKeyState,
  (state: State): boolean => state.loaded
);

export const getValidateStreamKeyLoading = createSelector(
  selectValidateStreamKeyState,
  (state: State): boolean => state.loading
);

export const getValidateStreamKeyError = createSelector(
  selectValidateStreamKeyState,
  (state: State): any => state.error
);
