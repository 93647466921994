import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-seven-segment-clock',
  templateUrl: './seven-segment-clock.component.html',
  styleUrls: ['./seven-segment-clock.component.scss']
})
export class SevenSegmentClockComponent implements OnInit {
  @Input() public size: number = 1;
  public hours: string = '00';
  public minutes: string = '00';
  public seconds: string = '00';

  // Segment display mapping for each digit
  private digitSegments: { [key: string]: boolean[] } = {
    '0': [true, true, true, true, true, true, false],
    '1': [false, true, true, false, false, false, false],
    '2': [true, true, false, true, true, false, true],
    '3': [true, true, true, true, false, false, true],
    '4': [false, true, true, false, false, true, true],
    '5': [true, false, true, true, false, true, true],
    '6': [true, false, true, true, true, true, true],
    '7': [true, true, true, false, false, false, false],
    '8': [true, true, true, true, true, true, true],
    '9': [true, true, true, true, false, true, true]
  };

  constructor(
    private readonly cdr: ChangeDetectorRef
  ) {
  }

  public ngOnInit(): void {
    setInterval(() => {
      const now = new Date();
      this.hours = this.padZero(now.getHours(), 2);
      this.minutes = this.padZero(now.getMinutes(), 2);
      this.seconds = this.padZero(now.getSeconds(), 2);

      this.cdr.detectChanges(); // Force change detection
    }, 1000); // Update every second
  }

  public isSegmentOn(digit: string, segment: number): boolean {
    const segments = this.digitSegments[digit];
    return segments ? segments[segment] : false;
  }

  private padZero(num: number, size: number): string {
    let s = num.toString();
    while (s.length < size) {
      s = '0' + s;
    }
    return s;
  }

}
