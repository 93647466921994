import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map} from 'rxjs/operators';
import {of} from 'rxjs';

import {
  StreamConfigurationsRequestParameters,
  StreamConfigurationsService
} from '../../../services/api/stream-configurations/stream-configurations.service';
import {
  loadStreamConfigurations,
  loadStreamConfigurationsFailure,
  loadStreamConfigurationsSuccess
} from './stream-configurations.load-all.actions';
import {StreamConfiguration} from '../../../services/api/stream-configurations/stream-configuration.model';
import {StreamConfigurationOrderBy} from '../../../services/api/streams/types/stream-configuration.order-by.type';
import {StreamConfigurationOrderDirection} from '../../../services/api/streams/types/stream-configuration.order.direction.type';


@Injectable()
export class StreamConfigurationsLoadAllEffects {

  // noinspection JSUnusedGlobalSymbols
  loadStreamConfigurations$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(loadStreamConfigurations),
        concatMap((parameters: StreamConfigurationsRequestParameters) => {
            const take: number | undefined = parameters.take;
            const skip: number | undefined = parameters.skip;
            const orderBy: StreamConfigurationOrderBy | undefined = parameters.orderBy;
            const orderDirection: StreamConfigurationOrderDirection | undefined = parameters.orderDirection;
            const filter: string | undefined = parameters.filter;
            return this.service.getAll(take, skip, orderBy, orderDirection, filter)
              .pipe(
                map((data: StreamConfiguration[]) => loadStreamConfigurationsSuccess({data})),
                catchError((error: any) => of(loadStreamConfigurationsFailure({error})))
              );
          }
        )
      );
  });


  constructor(
    private readonly actions$: Actions,
    private readonly service: StreamConfigurationsService
  ) {
  }

}
