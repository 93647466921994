import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromGrid from '../reducers/grid.reducer';
import {MultiviewView} from '../../../services/api/multiviews/multiview.view.model';
import {State} from '../reducers/grid.reducer';
import {Multiview} from '../../../services/api/multiviews/multiview-viewer.model';

export const selectGridState = createFeatureSelector<State>(
  fromGrid.gridFeatureKey
);

export const getGridRecord = createSelector(
  selectGridState,
  (state: State): Multiview | MultiviewView => state.record
);

export const getUpdatedGridRecord = createSelector(
  selectGridState,
  (state: State) => state.updatedRecord
);

export const getGridLoaded = createSelector(
  selectGridState,
  (state: State): boolean => state.loaded
);

export const getGridLoading = createSelector(
  selectGridState,
  (state: State): boolean => state.loading
);

export const getUpdatedVisibilityRecord = createSelector(
  selectGridState,
  (state: State) => state.updatedVisibility
);

export const getGridError = createSelector(
  selectGridState,
  (state: State): any => state.error
);
