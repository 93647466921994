import { createAction, props } from '@ngrx/store';
import {NdiInput} from '../../../services/api/instances/ndi-inputs/ndi-input.model';

export const loadNdiStreams = createAction(
  '[NdiStreams] Load NdiStreams',
  props<{ data: string }>()
);

export const loadNdiStreamsSuccess = createAction(
  '[NdiStreams] Load NdiStreams Success',
  props<{ data: NdiInput[] }>()
);

export const loadNdiStreamsFailure = createAction(
  '[NdiStreams] Load NdiStreams Failure',
  props<{ error: any }>()
);

export const clearNdiStreamsState = createAction(
  '[NdiStreams] clear NdiStreams State',
);
