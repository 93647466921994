import { createAction, props } from '@ngrx/store';
import {Cluster} from '../../../services/api/clusters/cluster.model';

export const loadCluster = createAction(
  '[Cluster] Load Cluster',
  props<{ data: string }>()
);

export const loadClusterSuccess = createAction(
  '[Cluster] Load Cluster Success',
  props<{ data: Cluster }>()
);

export const loadClusterFailure = createAction(
  '[Cluster] Load Cluster Failure',
  props<{ error: any }>()
);

export const clearClusterState = createAction(
  '[Cluster] clear Cluster State',
);
