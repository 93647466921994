import {createFeatureSelector, createSelector} from '@ngrx/store';
import {State} from './stream-configuration.load.reducer';

export const loadStreamConfigurationFeatureKey = 'streamConfiguration';
export const featureSelector = createFeatureSelector<State>(loadStreamConfigurationFeatureKey);

export const getStreamConfigurationRecord = createSelector(
  featureSelector,
  (state) => state.record
);

export const getStreamConfigurationLoaded = createSelector(
  featureSelector,
  (state) => state.loaded
);

export const getStreamConfigurationLoading = createSelector(
  featureSelector,
  (state) => state.loading
);

export const getStreamConfigurationError = createSelector(
  featureSelector,
  (state) => state.error
);
