import { createAction, props } from '@ngrx/store';
import {ProductionInstance} from '../../../services/api/production-instances/production-instance.model';

export const retryProductionInstance = createAction(
  '[RetryProductionInstance] Retry Production Instance',
  props<{ data: string }>()
);

export const retryProductionInstanceSuccess = createAction(
  '[RetryProductionInstance] Retry Production Instance Success',
  props<{ data: ProductionInstance }>()
);

export const retryProductionInstanceFailure = createAction(
  '[RetryProductionInstance] Retry Production Instance Failure',
  props<{ error: any }>()
);

export const clearRetryProductionInstanceState = createAction(
  '[RetryProductionInstance] Clear Production Instance State',
);
