import {PWGen} from './genpass/genpass';

export class Utils {

  public static makeRandomHash(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  public static sortArrayByKey(array: Array<any>, sortkey: string, direction: any = 'desc') {
    direction = (direction === 'desc' || direction === 1) ? 1 : -1;
    const sortArray = array.slice();
    sortArray.sort((c1, c2) => {
      const a = c1[sortkey].toLowerCase();
      const b = c2[sortkey].toLowerCase();
      if (a < b) {
        return -1 * direction;
      }
      if (a > b) {
        return 1 * direction;
      }
      return 0;
    });
    return sortArray;
  }

  public static naturalSortArrayByKey(array: Array<any>, sortkey: string, direction: any = 'desc') {
    const compareFn = new Intl.Collator('pl', {sensitivity: 'base', numeric: true}).compare;
    return array.slice().sort((a, b) => {
      const comparatorResult = compareFn(a[sortkey].toLowerCase(), b[sortkey].toLowerCase());
      return comparatorResult * (direction === 'desc' ? 1 : -1);
    });
  }

  public static generateStrongPassword(length: number) {
    const pwGen: PWGen = new PWGen(length, true, true, true);
    return pwGen.generate();
  }


  public static sortArrayByDate(array: Array<any>, dataKey: string) {
    const newArray = JSON.parse(JSON.stringify(array.slice()));
    return newArray.sort((a, b) => {
      return new Date(b[dataKey]).getTime() - new Date(a[dataKey]).getTime();
    });
  }

  public static convertToMbt(bytes: number): string | number | any {
    if (bytes === 0) {
      return 0 + 'bps';
    }
    const s = ['bps', 'Kbps', 'Mbps', 'Gbps', 'Tbps', 'Pbps'];
    let e = Math.floor(Math.log(bytes) / Math.log(1024));
    let value = ((bytes / Math.pow(1024, Math.floor(e))).toFixed(1));
    e = (e < 0) ? (-e) : e;
    value += ' ' + s[e];
    return value;
  }

  public static convertToMb(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  public static getRandomElementFromArray<T>(array: T[]): T | null {
    const length: number = array.length;
    if (length === 0) {
      return null;
    }

    const randomIndex: number = Math.floor(Math.random() * length);
    return array[randomIndex];
  }
}
