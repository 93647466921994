import {createAction, props} from '@ngrx/store';
import {DestinationStatus} from '../../../services/api/destinations/interfaces/destination-status.interface';

export const updateDestinationsStatuses = createAction(
  '[Destinations Statuses] Update Destinations Statuses',
  props<{ data: DestinationStatus[] }>()
);

export const updateDestinationsStatusesSuccess = createAction(
  '[Destinations Statuses] Update Destinations Statuses Success',
  props<{ data: DestinationStatus[] }>()
);
