import { createAction, props } from '@ngrx/store';

export const deleteStreamConfiguration = createAction(
  '[DeleteStreamConfiguration] Delete Stream Configuration',
  props<{ id: string }>()
);

export const deleteStreamConfigurationSuccess = createAction(
  '[DeleteStreamConfiguration] Delete Stream Configuration Success',
  props<{ id: string }>()
);

export const deleteStreamConfigurationFailure = createAction(
  '[DeleteStreamConfiguration] Delete Stream Configuration Failure',
  props<{ error: any }>()
);

export const clearDeleteStreamConfigurationState = createAction(
  '[DeleteInput] Clear Delete Stream Configuration State',
);
