import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {InputInterface} from '../../models/input.interface';

@Injectable({
  providedIn: 'root'
})
export class InputsService {

  constructor(private apiService: ApiService) {}

  private modifyInput(input: any): any {
    /* Just return object if something goes wrong */
    if (!input) {
      return input;
    }

    /* Set SRT outputs passphrases */
    const srtOutputPassphrase = input?.output?.passphrase || undefined;

    if (srtOutputPassphrase !== undefined) {

      if (input.clusters !== undefined) {
        input.clusters = input.clusters.map(cluster => {

          if (cluster.instances !== undefined) {
            cluster.instances
              .map(instance => {

                if (instance.srt !== undefined) {
                  instance.srt.password = srtOutputPassphrase;
                }

                return instance;
              });
          }

          return cluster;
        });
      }
    }

    return input;
  }

  public getInputs(): Observable<InputInterface[]> {
    return this.apiService.get(`/input`)
      .pipe(
        map( response => {
          const inputs = response.data;
          if (inputs) {
            response.data.inputs = inputs.map(input => this.modifyInput(input));
          }
          return response;
        }
      ));
  }

  getInput(inputId): Observable<any> {
    return this.apiService.get(`/input/${inputId}`);
  }

  createInput(data): Observable<any> {
    return this.apiService.put(`/input`, data);
  }

  updateInput(data): Observable<any> {
    return this.apiService.post(`/input/${data.id}`, data);
  }

  deleteInput(id): Observable<any> {
    return this.apiService.delete(`/input/${id}`);
  }

  validateStreamkey(key: string) {
    return this.apiService.get(`/input/validate/stream-key/${key}`);
  }

  getTranscodingProfiles(): Observable<any> {
    return this.apiService.get(`/transcoder/fps-scenario`);
  }
}
