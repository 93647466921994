import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map} from 'rxjs/operators';
import {of} from 'rxjs';

import * as NdiStreamsActions from '../actions/ndi-streams.actions';
import {NdiInputsService} from '../../../services/api/instances/ndi-inputs/ndi-inputs.service';
import {NdiInput} from '../../../services/api/instances/ndi-inputs/ndi-input.model';


@Injectable()
export class NdiStreamsEffects {

  loadNdiStreams$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NdiStreamsActions.loadNdiStreams),
      concatMap((payload) =>
        this.ndiInputsService.getAll(payload.data)
          .pipe(
            map((inputs: NdiInput[]) => NdiStreamsActions.loadNdiStreamsSuccess({data: inputs})),
            catchError(error => of(NdiStreamsActions.loadNdiStreamsFailure({error})))
          )
      )
    );
  });


  constructor(
    private readonly actions$: Actions,
    private readonly ndiInputsService: NdiInputsService
  ) {
  }

}
