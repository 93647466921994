import { createAction, props } from '@ngrx/store';
import {ProductionInstance} from '../../../services/api/production-instances/production-instance.model';

export const deleteVmixServer = createAction(
  '[DeleteVmixServer] Delete Vmix Server',
  props<{ data: string }>()
);

export const deleteVmixServerSuccess = createAction(
  '[DeleteVmixServer] Delete Vmix Server Success',
  props<{ data: ProductionInstance }>()
);

export const deleteVmixServerFailure = createAction(
  '[DeleteVmixServer] Delete Vmix Server Failure',
  props<{ error: any }>()
);

export const clearDeleteVmixServerState = createAction(
  '[DeleteVmixServer] Clear Delete Vmix Server State',
);
