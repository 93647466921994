import {createReducer, on} from '@ngrx/store';
import {
  clearCreateStreamConfigurationState,
  createStreamConfiguration,
  createStreamConfigurationFailure,
  createStreamConfigurationSuccess
} from './stream-configuration.create.actions';
import {StreamConfiguration} from '../../../services/api/stream-configurations/stream-configuration.model';

export interface State {
  record: StreamConfiguration | null;
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: null,
  error: null,
  loaded: false,
  loading: false
};


export const createStreamConfigurationReducer = createReducer(
  initialState,

  on(createStreamConfiguration, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: true
  })),
  on(createStreamConfigurationSuccess, (state, action) => ({
    record: action.data,
    error: null,
    loaded: true,
    loading: false
  })),
  on(createStreamConfigurationFailure, (state, action) => ({
    record: null,
    error: action.error,
    loaded: false,
    loading: false
  })),
  on(clearCreateStreamConfigurationState, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: false
  }))
);
