import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map} from 'rxjs/operators';
import {updateDestinationsStatuses, updateDestinationsStatusesSuccess} from './update-destinations-statuses.actions';
import {DestinationStatus} from '../../../services/api/destinations/interfaces/destination-status.interface';

@Injectable({providedIn: 'root'})
export class UpdateDestinationsStatusesEffects {

  // noinspection JSUnusedGlobalSymbols
  updateDestinationsStatuses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateDestinationsStatuses),
      map((action: {data: DestinationStatus[]}) => updateDestinationsStatusesSuccess({data: action.data}))
    ));

  constructor(
    private readonly actions$: Actions
  ) {
  }
}
