import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '../../environments/environment';
import {EffectsModule} from '@ngrx/effects';

/* Reducers */
import * as fromCurrentUser from './users/reducers/current-user.reducer';
import * as fromUsers from './users/reducers/users.reducer';
import * as fromUser from './users/reducers/user.reducer';
import * as fromUserUpdate from './users/reducers/user-update.reducer';
import * as fromUserDelete from './users/reducers/user-delete.reducer';
import * as fromUsersRoles from './users/reducers/users-roles.reducer';
import * as fromUserInvite from './users/reducers/user-invite.reducer';
import {loadAllStreamConfigurationReducer} from './stream-configurations/load-all/stream-configurations.load-all.reducer';
import {loadStreamConfigurationReducer} from './stream-configurations/load/stream-configuration.load.reducer';
import {updateStreamConfigurationReducer} from './stream-configurations/update/stream-configuration.update.reducer';
import {validateStreamKeyReducer} from './stream-configurations/stream-key-validation/stream-key.validation.reducer';
import {deleteStreamConfigurationReducer} from './stream-configurations/delete/stream-configuration.delete.reducer';
import {loadNdiSignalsReducer} from './ndi-signals/ndi-signals.reducer';
import * as fromDestination from './destinations/reducers/destination.reducer';
import * as fromDestinations from './destinations/reducers/destinations.reducer';
import * as fromCreateDestination from './destinations/reducers/create-destination.reducer';
import * as fromUpdateDestination from './destinations/reducers/update-destination.reducer';
import * as fromDeleteDestination from './destinations/reducers/delete-destination.reducer';
import * as fromStartDestination from './destinations/reducers/start-destination.reducer';
import * as fromStopDestination from './destinations/reducers/stop-destination.reducer';
import * as fromAllInstanceTypes from './instances/reducers/all-instance-types.reducer';
import * as fromClusters from './clusters/reducers/clusters.reducer';
import * as fromCluster from './clusters/reducers/cluster.reducer';
import * as fromCreateCluster from './clusters/reducers/create-cluster.reducer';
import * as fromDeleteCluster from './clusters/reducers/delete-cluster.reducer';
import * as fromAllClusterRegions from './clusters/reducers/all-cluster-regions.reducer';
import * as fromGrid from './grids/reducers/grid.reducer';
import * as fromGrids from './grids/reducers/grids.reducer';
import * as fromCreateGrid from './grids/reducers/create-grid.reducer';
import * as fromDeleteGrid from './grids/reducers/delete-grid.reducer';
import * as fromUpdateGrid from './grids/reducers/update-grid.reducer';
import * as fromValidateShortname from './grids/reducers/validate-shortname.reducer';
import * as fromRecords from './dvr/reducers/records.reducer';
import * as fromStartRecord from './dvr/reducers/start-record.reducer';
import * as fromStopRecord from './dvr/reducers/stop-record.reducer';
import * as fromDeleteRecord from './dvr/reducers/delete-record.reducer';
import * as fromRecordStatus from './dvr/reducers/record-status.reducer';
import * as fromOrganizations from './organizations/reducers/organizations.reducer';
import * as fromCurrentOrganization from './organizations/reducers/current-organization.reducer';
import * as fromOrganization from './organizations/reducers/organization.reducer';
import * as fromCreateOrganization from './organizations/reducers/create-organization.reducer';
import * as fromUpdateOrganization from './organizations/reducers/update-organization.reducer';
import * as fromDeleteOrganization from './organizations/reducers/delete-organization.reducer';
import * as fromChangeOrganization from './organizations/reducers/change-organization.reducer';
import * as fromValidateDns from './organizations/reducers/validate-dns.reducer';
import * as fromResetPassword from './auth/reset-password/reducers/reset-password.reducer';
import * as fromUpdateAccount from './account/reducers/update-account.reducer';
import * as fromDeleteAccount from './account/reducers/delete-account.reducer';
import * as fromUpdateAccountPassword from './account/reducers/update-account-password.reducer';
import * as fromActivateInvitation from './auth/activate-invitation/reducers/activate-invitation.reducer';
import * as fromClipsRecord from './clips/reducers/clips.reducer';
import * as fromCutVideoRecord from './clips/reducers/cut-video.reducer';
import * as fromDeleteClipRecord from './clips/reducers/delete-clip.reducer';
import * as fromUpdateClipRecord from './clips/reducers/update-clip.reducer';
import * as fromCreateVmixServerRecord from './production/reducers/create-vmix-server.reducer';
import * as fromDeleteVmixServerRecord from './production/reducers/delete-vmix-server.reducer';
import * as fromStartVmixServerRecord from './production/reducers/start-vmix-server.reducer';
import * as fromRetryProductionInstanceRecord from './production/reducers/retry-production-instance.reducer';
import * as fromStopVmixServerRecord from './production/reducers/stop-vmix-server.reducer';
import * as fromVmixServerStatusRecord from './production/reducers/vmix-server-status.reducer';
import * as fromVmixServersRecord from './production/reducers/vmix-servers.reducer';
import * as fromStatisticsRecord from './statistics/reducers/statistics.reducer';
import * as fromBillingPeriodsRecord from './statistics/reducers/billing-periods.reducer';
import * as frompaymentInfoRecord from './payment/reducers/payment-info.reducer';
import * as fromIncomeBandwidthRecord from './dashboard/reducers/income-bandwidth.reducer';
import * as fromOutcomeBandwidthRecord from './dashboard/reducers/outcome-bandwidth.reducer';
import * as fromBandwidthSettingsRecord from './dashboard/reducers/bandwidth-settings.reducer';
import * as fromChartTimeRecord from './dashboard/reducers/chart-time.reducer';
import * as fromStatisticsClusterRecord from './dashboard/reducers/statistics-cluster.reducer';
import * as fromTranscodersRecord from './dashboard/reducers/transcoders.reducer';
import * as fromTranscodersSettingsRecord from './dashboard/reducers/transcoders-settings.reducer';
import * as fromStorageRecord from './dashboard/reducers/storage.reducer';
import * as fromStorageSettingsRecord from './dashboard/reducers/storage-settings.reducer';
import * as fromMonitoringClusterRecord from './dashboard/reducers/monitoring-cluster.reducer';
import {liveMonitoringReducer} from './dashboard/live-monitoring/live-monitoring.reducer';
import {updateDestinationsStatusesReducer} from './destinations/update-destinatons-statuses/update-destinations-statuses.reducer';
import {createStreamConfigurationReducer} from './stream-configurations/create/stream-configuration.create.reducer';
import {loadDestinationsStatusesReducer} from './destinations/statuses/destinations-statuses.reducer';

/* Effects */
import {CurrentUserEffects} from './users/effects/current-user.effects';
import {UserEffects} from './users/effects/user.effects';
import {UsersEffects} from './users/effects/users.effects';
import {UserUpdateEffects} from './users/effects/user-update.effects';
import {UserDeleteEffects} from './users/effects/user-delete.effects';
import {UsersRolesEffects} from './users/effects/users-roles.effects';
import {UserInviteEffects} from './users/effects/user-invite.effects';
import {StreamConfigurationCreateEffects} from './stream-configurations/create/stream-configuration.create.effects';
import {StreamConfigurationLoadEffects} from './stream-configurations/load/stream-configuration.load.effects';
import {StreamConfigurationUpdateEffects} from './stream-configurations/update/stream-configuration.update.effects';
import {ValidateStreamKeyEffects} from './stream-configurations/stream-key-validation/stream-key.validation.effects';
import {DestinationEffects} from './destinations/effects/destination.effects';
import {DestinationsEffects} from './destinations/effects/destinations.effects';
import {CreateDestinationEffects} from './destinations/effects/create-destination.effects';
import {UpdateDestinationEffects} from './destinations/effects/update-destination.effects';
import {DeleteDestinationEffects} from './destinations/effects/delete-destination.effects';
import {StartDestinationEffects} from './destinations/effects/start-destination.effects';
import {StopDestinationEffects} from './destinations/effects/stop-destination.effects';
import {AllInstanceTypesEffects} from './instances/effects/all-instance-types.effects';
import {StreamConfigurationDeleteEffects} from './stream-configurations/delete/stream-configuration.delete.effects';
import {NdiSignalsEffects} from './ndi-signals/ndi-signals.effects';
import {ClustersEffects} from './clusters/effects/clusters.effects';
import {ClusterEffects} from './clusters/effects/cluster.effects';
import {CreateClusterEffects} from './clusters/effects/create-cluster.effects';
import {DeleteClusterEffects} from './clusters/effects/delete-cluster.effects';
import {AllClusterRegionsEffects} from './clusters/effects/all-cluster-regions.effects';
import {GridEffects} from './grids/effects/grid.effects';
import {GridsEffects} from './grids/effects/grids.effects';
import {CreateGridEffects} from './grids/effects/create-grid.effects';
import {DeleteGridEffects} from './grids/effects/delete-grid.effects';
import {UpdateGridEffects} from './grids/effects/update-grid.effects';
import {ValidateShortnameEffects} from './grids/effects/validate-shortname.effects';
import {RecordsEffects} from './dvr/effects/records.effects';
import {StartRecordEffects} from './dvr/effects/start-record.effects';
import {StopRecordEffects} from './dvr/effects/stop-record.effects';
import {DeleteRecordEffects} from './dvr/effects/delete-record.effects';
import {OrganizationsEffects} from './organizations/effects/organizations.effects';
import {CurrentOrganizationEffects} from './organizations/effects/current-organization.effects';
import {OrganizationEffects} from './organizations/effects/organization.effects';
import {CreateOrganizationEffects} from './organizations/effects/create-organization.effects';
import {UpdateOrganizationEffects} from './organizations/effects/update-organization.effects';
import {DeleteOrganizationEffects} from './organizations/effects/delete-organization.effects';
import {ChangeOrganizationEffects} from './organizations/effects/change-organization.effects';
import {ValidateDnsEffects} from './organizations/effects/validate-dns.effects';
import {ResetPasswordEffects} from './auth/reset-password/effects/reset-password.effects';
import {UpdateAccountEffects} from './account/effects/update-account.effects';
import {DeleteAccountEffects} from './account/effects/delete-account.effects';
import {UpdateAccountPasswordEffects} from './account/effects/update-account-password.effects';
import {ActivateInvitationEffects} from './auth/activate-invitation/effects/activate-invitation.effects';
import {ClipsEffects} from './clips/effects/clips.effects';
import {CutVideoEffects} from './clips/effects/cut-video.effects';
import {DeleteClipEffects} from './clips/effects/delete-clip.effects';
import {UpdateClipEffects} from './clips/effects/update-clip.effects';
import {CreateVmixServerEffects} from './production/effects/create-vmix-server.effects';
import {DeleteVmixServerEffects} from './production/effects/delete-vmix-server.effects';
import {StartVmixServerEffects} from './production/effects/start-vmix-server.effects';
import {RetryProductionInstanceEffects} from './production/effects/retry-production-instance.effects';
import {StopVmixServerEffects} from './production/effects/stop-vmix-server.effects';
import {VmixServersEffects} from './production/effects/vmix-servers.effects';
import {StatisticsEffects} from './statistics/effects/statistics.effects';
import {BillingPeriodsEffects} from './statistics/effects/billing-periods.effects';
import {PaymentInfoEffects} from './payment/effects/payment-info.effects';
import {IncomeBandwidthEffects} from './dashboard/effects/income-bandwidth.effects';
import {OutcomeBandwidthEffects} from './dashboard/effects/outcome-bandwidth.effects';
import {TranscodersEffects} from './dashboard/effects/transcoders.effects';
import {StorageEffects} from './dashboard/effects/storage.effects';
import {LiveMonitoringEffects} from './dashboard/live-monitoring/live-monitoring.effects';
import {UpdateDestinationsStatusesEffects} from './destinations/update-destinatons-statuses/update-destinations-statuses.effects';
import {StreamConfigurationsLoadAllEffects} from './stream-configurations/load-all/stream-configurations.load-all.effects';
import {DestinationsStatusesEffects} from './destinations/statuses/destinations-statuses.effects';

/* Selectors */
import {liveMonitoringFeatureKey} from './dashboard/live-monitoring/live-monitoring.selector';
import {destinationsStatusesUpdatesFeatureKey} from './destinations/update-destinatons-statuses/update-destinations-statuses.selectors';
import {createStreamConfigurationFeatureKey} from './stream-configurations/create/stream-configuration.create.selectors';
import {updateStreamConfigurationFeatureKey} from './stream-configurations/update/stream-configuration.update.selectors';
import {loadStreamConfigurationFeatureKey} from './stream-configurations/load/stream-configuration.load.selectors';
import {deleteStreamConfigurationFeatureKey} from './stream-configurations/delete/stream-configuration.delete.selectors';
import {loadAllStreamConfigurationFeatureKey} from './stream-configurations/load-all/stream-configurations.load-all.selectors';
import {getNdiSignalsFeatureKey} from './ndi-signals/ndi-signals.selectors';
import {validateStreamKeyFeatureKey} from './stream-configurations/stream-key-validation/stream-key.validation.selectors';
import {destinationsStatusesFeatureKey} from './destinations/statuses/destinations-statuses.selectors';


@NgModule({
  declarations: [],
  imports: [
    StoreModule.forRoot({}),
    StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}),
    StoreModule.forFeature(fromCurrentUser.userFeatureKey, fromCurrentUser.reducer),
    StoreModule.forFeature(fromUsers.usersFeatureKey, fromUsers.reducer),
    StoreModule.forFeature(fromUser.userFeatureKey, fromUser.reducer),
    StoreModule.forFeature(fromUserUpdate.userUpdateFeatureKey, fromUserUpdate.reducer),
    StoreModule.forFeature(fromUserDelete.userDeleteFeatureKey, fromUserDelete.reducer),
    StoreModule.forFeature(fromUsersRoles.usersRolesFeatureKey, fromUsersRoles.reducer),
    StoreModule.forFeature(fromUserInvite.userInviteFeatureKey, fromUserInvite.reducer),
    StoreModule.forFeature(fromClusters.clustersFeatureKey, fromClusters.reducer),
    StoreModule.forFeature(fromCluster.clusterFeatureKey, fromCluster.reducer),
    StoreModule.forFeature(fromAllClusterRegions.allClusterRegionsFeatureKey, fromAllClusterRegions.reducer),
    StoreModule.forFeature(loadAllStreamConfigurationFeatureKey, loadAllStreamConfigurationReducer),
    StoreModule.forFeature(loadStreamConfigurationFeatureKey, loadStreamConfigurationReducer),
    StoreModule.forFeature(updateStreamConfigurationFeatureKey, updateStreamConfigurationReducer),
    StoreModule.forFeature(getNdiSignalsFeatureKey, loadNdiSignalsReducer),
    StoreModule.forFeature(fromDestination.destinationFeatureKey, fromDestination.reducer),
    StoreModule.forFeature(fromDestinations.destinationsFeatureKey, fromDestinations.reducer),
    StoreModule.forFeature(fromCreateDestination.createDestinationFeatureKey, fromCreateDestination.reducer),
    StoreModule.forFeature(fromUpdateDestination.updateDestinationFeatureKey, fromUpdateDestination.reducer),
    StoreModule.forFeature(fromDeleteDestination.deleteDestinationFeatureKey, fromDeleteDestination.reducer),
    StoreModule.forFeature(fromStartDestination.startDestinationFeatureKey, fromStartDestination.reducer),
    StoreModule.forFeature(fromStopDestination.stopDestinationFeatureKey, fromStopDestination.reducer),
    StoreModule.forFeature(fromAllInstanceTypes.allInstanceTypesFeatureKey, fromAllInstanceTypes.reducer),
    StoreModule.forFeature(deleteStreamConfigurationFeatureKey, deleteStreamConfigurationReducer),
    StoreModule.forFeature(fromCreateCluster.createClusterFeatureKey, fromCreateCluster.reducer),
    StoreModule.forFeature(fromDeleteCluster.deleteClusterFeatureKey, fromDeleteCluster.reducer),
    StoreModule.forFeature(fromGrid.gridFeatureKey, fromGrid.reducer),
    StoreModule.forFeature(fromGrids.gridsFeatureKey, fromGrids.reducer),
    StoreModule.forFeature(fromCreateGrid.createGridFeatureKey, fromCreateGrid.reducer),
    StoreModule.forFeature(fromDeleteGrid.deleteGridFeatureKey, fromDeleteGrid.reducer),
    StoreModule.forFeature(fromUpdateGrid.updateGridFeatureKey, fromUpdateGrid.reducer),
    StoreModule.forFeature(fromValidateShortname.validateShortnameFeatureKey, fromValidateShortname.reducer),
    StoreModule.forFeature(fromRecords.recordsFeatureKey, fromRecords.reducer),
    StoreModule.forFeature(fromStartRecord.startRecordFeatureKey, fromStartRecord.reducer),
    StoreModule.forFeature(fromStopRecord.stopRecordFeatureKey, fromStopRecord.reducer),
    StoreModule.forFeature(fromDeleteRecord.deleteRecordFeatureKey, fromDeleteRecord.reducer),
    StoreModule.forFeature(fromOrganizations.organizationsFeatureKey, fromOrganizations.reducer),
    StoreModule.forFeature(fromCurrentOrganization.currentOrganizationFeatureKey, fromCurrentOrganization.reducer),
    StoreModule.forFeature(fromOrganization.organizationFeatureKey, fromOrganization.reducer),
    StoreModule.forFeature(fromChangeOrganization.changeOrganizationFeatureKey, fromChangeOrganization.reducer),
    StoreModule.forFeature(fromCreateOrganization.createOrganizationFeatureKey, fromCreateOrganization.reducer),
    StoreModule.forFeature(fromUpdateOrganization.updateOrganizationFeatureKey, fromUpdateOrganization.reducer),
    StoreModule.forFeature(fromDeleteOrganization.deleteOrganizationFeatureKey, fromDeleteOrganization.reducer),
    StoreModule.forFeature(fromResetPassword.resetPasswordFeatureKey, fromResetPassword.reducer),
    StoreModule.forFeature(fromUpdateAccount.updateAccountFeatureKey, fromUpdateAccount.reducer),
    StoreModule.forFeature(fromDeleteAccount.deleteAccountFeatureKey, fromDeleteAccount.reducer),
    StoreModule.forFeature(fromActivateInvitation.activateInvitationFeatureKey, fromActivateInvitation.reducer),
    StoreModule.forFeature(fromClipsRecord.clipsFeatureKey, fromClipsRecord.reducer),
    StoreModule.forFeature(fromCutVideoRecord.cutVideoFeatureKey, fromCutVideoRecord.reducer),
    StoreModule.forFeature(fromDeleteClipRecord.deleteClipFeatureKey, fromDeleteClipRecord.reducer),
    StoreModule.forFeature(fromUpdateClipRecord.updateClipFeatureKey, fromUpdateClipRecord.reducer),
    StoreModule.forFeature(fromUpdateAccountPassword.updateAccountPasswordFeatureKey, fromUpdateAccountPassword.reducer),
    StoreModule.forFeature(fromRecordStatus.recordStatusFeatureKey, fromRecordStatus.reducer),
    StoreModule.forFeature(fromValidateDns.validateDnsFeatureKey, fromValidateDns.reducer),
    StoreModule.forFeature(fromRecordStatus.recordStatusFeatureKey, fromRecordStatus.reducer),
    StoreModule.forFeature(fromCreateVmixServerRecord.createVmixServerFeatureKey, fromCreateVmixServerRecord.reducer),
    StoreModule.forFeature(fromDeleteVmixServerRecord.deleteVmixServerFeatureKey, fromDeleteVmixServerRecord.reducer),
    StoreModule.forFeature(fromStartVmixServerRecord.startVmixServerFeatureKey, fromStartVmixServerRecord.reducer),
    StoreModule.forFeature(fromRetryProductionInstanceRecord.retryProductionInstanceFeatureKey, fromRetryProductionInstanceRecord.reducer),
    StoreModule.forFeature(fromStopVmixServerRecord.stopVmixServerFeatureKey, fromStopVmixServerRecord.reducer),
    StoreModule.forFeature(fromVmixServerStatusRecord.vmixServerStatusFeatureKey, fromVmixServerStatusRecord.reducer),
    StoreModule.forFeature(fromVmixServersRecord.vmixServersFeatureKey, fromVmixServersRecord.reducer),
    StoreModule.forFeature(fromStatisticsRecord.statisticsFeatureKey, fromStatisticsRecord.reducer),
    StoreModule.forFeature(fromBillingPeriodsRecord.billingPeriodsFeatureKey, fromBillingPeriodsRecord.reducer),
    StoreModule.forFeature(frompaymentInfoRecord.paymentInfoFeatureKey, frompaymentInfoRecord.reducer),
    StoreModule.forFeature(fromIncomeBandwidthRecord.incomeBandwidthFeatureKey, fromIncomeBandwidthRecord.reducer),
    StoreModule.forFeature(fromOutcomeBandwidthRecord.outcomeBandwidthFeatureKey, fromOutcomeBandwidthRecord.reducer),
    StoreModule.forFeature(fromBandwidthSettingsRecord.bandwidthSettingsFeatureKey, fromBandwidthSettingsRecord.reducer),
    StoreModule.forFeature(fromChartTimeRecord.chartTimeFeatureKey, fromChartTimeRecord.reducer),
    StoreModule.forFeature(fromStatisticsClusterRecord.statisticsClusterFeatureKey, fromStatisticsClusterRecord.reducer),
    StoreModule.forFeature(fromTranscodersRecord.transcodersFeatureKey, fromTranscodersRecord.reducer),
    StoreModule.forFeature(fromTranscodersSettingsRecord.transcodersSettingsFeatureKey, fromTranscodersSettingsRecord.reducer),
    StoreModule.forFeature(fromStorageRecord.storageFeatureKey, fromStorageRecord.reducer),
    StoreModule.forFeature(fromStorageSettingsRecord.storageSettingsFeatureKey, fromStorageSettingsRecord.reducer),
    StoreModule.forFeature(fromMonitoringClusterRecord.monitoringClusterFeatureKey, fromMonitoringClusterRecord.reducer),
    StoreModule.forFeature(liveMonitoringFeatureKey, liveMonitoringReducer),
    StoreModule.forFeature(destinationsStatusesUpdatesFeatureKey, updateDestinationsStatusesReducer),
    StoreModule.forFeature(createStreamConfigurationFeatureKey, createStreamConfigurationReducer),
    StoreModule.forFeature(validateStreamKeyFeatureKey, validateStreamKeyReducer),
    StoreModule.forFeature(destinationsStatusesFeatureKey, loadDestinationsStatusesReducer),
    EffectsModule.forRoot([
      CurrentUserEffects,
      UserEffects,
      UsersEffects,
      UserUpdateEffects,
      UserDeleteEffects,
      UsersRolesEffects,
      UserInviteEffects,
      ClustersEffects,
      StreamConfigurationsLoadAllEffects,
      StreamConfigurationCreateEffects,
      StreamConfigurationLoadEffects,
      StreamConfigurationUpdateEffects,
      NdiSignalsEffects,
      DestinationEffects,
      DestinationsEffects,
      CreateDestinationEffects,
      UpdateDestinationEffects,
      UpdateDestinationsStatusesEffects,
      StartDestinationEffects,
      StopDestinationEffects,
      StreamConfigurationDeleteEffects,
      ClusterEffects,
      CreateClusterEffects,
      DeleteClusterEffects,
      DeleteDestinationEffects,
      GridEffects,
      GridsEffects,
      CreateGridEffects,
      DeleteGridEffects,
      UpdateGridEffects,
      ValidateShortnameEffects,
      RecordsEffects,
      StartRecordEffects,
      StopRecordEffects,
      DeleteRecordEffects,
      ValidateStreamKeyEffects,
      OrganizationsEffects,
      CurrentOrganizationEffects,
      OrganizationEffects,
      CreateOrganizationEffects,
      UpdateOrganizationEffects,
      DeleteOrganizationEffects,
      AllInstanceTypesEffects,
      AllClusterRegionsEffects,
      ResetPasswordEffects,
      UpdateAccountEffects,
      ChangeOrganizationEffects,
      ActivateInvitationEffects,
      ClipsEffects,
      CutVideoEffects,
      DeleteClipEffects,
      UpdateClipEffects,
      UpdateAccountPasswordEffects,
      DeleteAccountEffects,
      ValidateDnsEffects,
      CreateVmixServerEffects,
      DeleteVmixServerEffects,
      StartVmixServerEffects,
      RetryProductionInstanceEffects,
      StopVmixServerEffects,
      VmixServersEffects,
      StatisticsEffects,
      BillingPeriodsEffects,
      PaymentInfoEffects,
      IncomeBandwidthEffects,
      OutcomeBandwidthEffects,
      TranscodersEffects,
      StorageEffects,
      LiveMonitoringEffects,
      DestinationsStatusesEffects
    ])
  ],
  providers: []
})
export class EntityStoreModule {
  constructor() {
  }
}
