import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './destinations-statuses.reducer';
import {DestinationStatus} from '../../../services/api/destinations/interfaces/destination-status.interface';

export const destinationsStatusesFeatureKey = 'DestinationsStatuses';

export const selectDestinationsStatuesState = createFeatureSelector<State>(
  destinationsStatusesFeatureKey
);

export const getDestinationsStatuses = createSelector(
  selectDestinationsStatuesState,
  (state: State): DestinationStatus[] => state.record
);

export const getDestinationsStatusesLoaded = createSelector(
  selectDestinationsStatuesState,
  (state: State): boolean => state.loaded
);

export const getDestinationsStatusesLoading = createSelector(
  selectDestinationsStatuesState,
  (state: State): boolean => state.loading
);

export const getDestinationsStatusesError = createSelector(
  selectDestinationsStatuesState,
  (state: State): any => state.error
);
