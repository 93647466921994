import {createAction, props} from '@ngrx/store';
import {DestinationStatus} from '../../../services/api/destinations/interfaces/destination-status.interface';

export const loadDestinationsStatues = createAction(
  '[DestinationsStatuses] Load Destinations Statuses'
);

export const loadDestinationsStatusesSuccess = createAction(
  '[DestinationsStatuses] Load Destinations Statuses Success',
  props<{ data: DestinationStatus[] }>()
);

export const loadDestinationsStatusesFailure = createAction(
  '[DestinationsStatuses] Load Destinations Statuses Failure',
  props<{ error: any }>()
);

export const clearDestinationsState = createAction(
  '[DestinationsStatuses] Clear Destinations Statuses State',
);
