import { createReducer, on } from '@ngrx/store';
import * as InputsActions from '../actions/inputs.actions';
import { Utils } from '../../../services/Utils';
import { DataTransform } from '../../../services/DataTransform';

export const inputsFeatureKey = 'inputs';

export interface State {
  record: any[];
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: [],
  error: null,
  loaded: false,
  loading: false,
};


export const reducer = createReducer(
  initialState,

  on(InputsActions.loadInputs, (state) => ({
    ...state,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(InputsActions.loadInputsSuccess, (state, action) => ({
    record: Utils.naturalSortArrayByKey(action.data, 'inputName'),
    error: null,
    loaded: true,
    loading: false,
  })),
  on(InputsActions.loadInputStatusSuccess, (state, action) => ({
    record: DataTransform.updateInputStatusAndSLDPLink(state.record, action.data.data),
    error: null,
    loaded: true,
    loading: false,
  })),
  on(InputsActions.loadInputsFailure, (state, action) => ({
    record: [],
    error: action.error,
    loaded: false,
    loading: false,
  })),
  on(InputsActions.clearInputsState, () => ({
    record: [],
    error: null,
    loaded: false,
    loading: false,
  }))
);
