import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as CreateClusterActions from '../actions/create-cluster.actions';
import { ClustersService } from '../../../services/api/clusters/clusters.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';



@Injectable()
export class CreateClusterEffects {

  createCluster$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CreateClusterActions.createCluster),
      concatMap((payload) =>
        this.clustersService.createCluster(payload.data).pipe(
          map(data => CreateClusterActions.createClusterSuccess({ data })),
          catchError(error => of(CreateClusterActions.createClusterFailure({ error }))))
      )
    );
  });

  createClusterSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CreateClusterActions.createClusterSuccess),
      tap(() => this.snackBar.open('Cluster successfully created', 'Success', {duration: 3000})),
      tap(() => this.router.navigate(['/clusters']))
    );
  }, { dispatch: false });



  constructor(private actions$: Actions,
              private clustersService: ClustersService,
              private router: Router,
              private snackBar: MatSnackBar) {}

}
