import {Injectable} from '@angular/core';
import {ApiService} from '../api.service';
import {Observable} from 'rxjs';
import {Cluster, CreateClusterPayload, DeleteClusterPayload} from './models/cluster.model';

@Injectable({
  providedIn: 'root'
})
export class ClustersService {

  constructor(
    private readonly apiService: ApiService
  ) {
  }

  public getClusters(): Observable<Cluster[]> {
    return this.apiService.get(`/clusters`);
  }

  public getCluster(clusterId: string): Observable<Cluster> {
    return this.apiService.get(`/clusters/${clusterId}`);
  }

  public createCluster(data: CreateClusterPayload): Observable<Cluster> {
    return this.apiService.post(`/clusters`, data);
  }

  public deleteCluster(data: DeleteClusterPayload): Observable<Cluster> {
    const parameters = {
      isDeleteDvrBucket: data.isDeleteDvrBucket,
      isDeleteClipsBucket: data.isDeleteClipsBucket
    }
    return this.apiService.delete(`/clusters/${data.clusterId}`, parameters);
  }

  public stopCluster(clusterId: string): Observable<Cluster> {
    return this.apiService.get(`/clusters/${clusterId}/stop`);
  }

  public startCluster(clusterId: string): Observable<Cluster> {
    return this.apiService.get(`/clusters/${clusterId}/start`);
  }

  public getClusterRegions(): Observable<any> {
    return this.apiService.get(`/organizations/cluster-regions`);
  }

  public getAllClusterRegions(): Observable<any> {
    return this.apiService.get(`/voc/cluster-regions`);
  }
}
